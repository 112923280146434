import classNames from "classnames/bind";
import { useDispatch } from "react-redux";

import { IconHistory } from "../../assets/icons";

import { setPopupNoteHistory } from "../../store/services";

import styles from "./CallNoteHistory.module.scss";

const cx = classNames.bind(styles);

const CallNoteHistory = () => {
  const dispatch = useDispatch();

  const handleCallNoteHistory = () => dispatch(setPopupNoteHistory(true));
  
  return (
    <button
      className={cx("open-call-note-history")}
      onClick={handleCallNoteHistory}
    >
      <IconHistory size="24px" />
    </button>
  );
};

export default CallNoteHistory;
