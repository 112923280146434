import React from "react";

import classnames from "classnames/bind";

import styles from "./DotSpinner.module.scss";

const cx = classnames.bind(styles);

function DotSpinner() {
    return (
        <div className={cx("dot-spinner")}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((dot: number) => {
                return (
                    <div
                        key={dot}
                        className={cx("dot")}
                    />
                );
            })}
        </div>
    );
}


export default DotSpinner;
