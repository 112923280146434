import React from "react";

import classNames from "classnames/bind";

import styles from "./PopupDeleteCustomCallType.module.scss";
import { IconClose } from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../store";
import { dataCallTypes, EKeyCallType } from "../../../constants";
import ConfirmDeleteCustomCallTypeButton from "../../buttons/ConfirmDeleteCustomCallTypeButton";
import DotSpinner from "../../spinners/DotSpinner";
import { setType, useDeleteCustomCallTypeLazy, useGetCallTypesQuery } from "../../../store/services";

const cx = classNames.bind(styles);

interface IPopupDeleteCustomCallTypeProps {
  onClose: () => void;
}

const PopupDeleteCustomCallType = ({ onClose }: IPopupDeleteCustomCallTypeProps) => {
  const dispatch = useDispatch();

  const popups = useSelector((state: IState) => state.popupReducer);
  const activeType = useSelector(
    (state: IState) => state.activeTypeReducer.type,
  );

  const [deleteCallType, { isLoading: isDeleting }] = useDeleteCustomCallTypeLazy();
  const userEmail = useSelector((state: IState) => state.userReducer.email);
  const { data: callTypes, refetch } = useGetCallTypesQuery(userEmail);

  const callType = popups.popupDeleteCustomCallType.callType!;

  const id = callType[EKeyCallType.ID]!;
  const name = callType[EKeyCallType.NAME];

  return (
    <div className={cx("popup-delete-custom-call-type")}>
      <button className={cx("close")} onClick={onClose}>
        <IconClose size="16px" />
      </button>
      <h4>Delete Custom Call Type</h4>

      <div className={cx("question")}>
        Do you really want to delete <span className={cx("call-type-name")}>{name}</span> call type?
      </div>
      <div className={cx("button-wrapper")}>
        {
          isDeleting ?
            (
              <DotSpinner />
            ) : (
              <ConfirmDeleteCustomCallTypeButton
                onClick={() => {
                  deleteCallType({
                    id,
                  }).then((res) => {
                    const { data } = res;

                    if (data && data[0].status === 200) {
                      if (activeType && activeType[EKeyCallType.ID] === id) {
                        const otherCustomType = callTypes?.find(callType => callType[EKeyCallType.ID] !== id);

                        if (otherCustomType) {
                          dispatch(setType(otherCustomType));
                        } else {
                          dispatch(setType(dataCallTypes[0]));
                        }
                      }

                      refetch();

                      onClose();
                    }
                  });
                }}
              />
            )
        }
      </div>
    </div>
  );
};

export default PopupDeleteCustomCallType;
