import React, { useEffect, useMemo } from "react";

import styles from "./PMSFieldsForm.module.scss";
import classNames from "classnames/bind";
import { useController, useForm } from "react-hook-form";
import { EDataKeys, TData } from "../../types/TData";
import DatePicker from "../DatePicker/DatePicker.com";
import Select from "../SelectNew/Select";
import {
    OCIEnvironmentOptions,
    operaMultiPropertyOptions,
    operaSCOptions,
    operaTypeOptions,
    operaVersionOptions,
    oracleDealerOptions,
    posOptions
} from "../../constants";
import { useUpdateProjectMutation } from "../../store/services";
import SlideCheckbox from "../SlideCheckbox";
import { debounce } from "../../helpers";
import Input from "../Input/Input";

const cx = classNames.bind(styles);

function PMSFieldsForm({ project }: Props) {
    const [updateProject] = useUpdateProjectMutation();

    const debouncedUpdateProject = useMemo(() => debounce(updateProject, 500), [updateProject]);

    const { control, reset } = useForm<FormValues>({
        defaultValues: {
            oracleDealer: project[EDataKeys.VENDORS_ORACLE] || "",
            operaVersion: project[EDataKeys.OPERA_VERSION] || "",
            operaType: project[EDataKeys.OPERA_TYPE] || "",
            operaSC: project[EDataKeys.OPERA_SC] || "",
            operaMultiProperty: project[EDataKeys.OPERA_MULTI_PROPERTY] || "",
            operaConfigDate: project[EDataKeys.OPERA_CONFIG_DATE] ? new Date(project[EDataKeys.OPERA_CONFIG_DATE]) : null,
            operaCountDownDate: project[EDataKeys.OPERA_COUNTDOWN_DATE] ? new Date(project[EDataKeys.OPERA_COUNTDOWN_DATE]) : null,
            lockCountDownDate: project[EDataKeys.LOCK_COUNTDOWN_DATE] ?? false,
            ociEnvironment: project[EDataKeys.OCI_ENVIRONMENT] ?? "",
            vendorsPOS: project[EDataKeys.VENDORS_POS] ?? "",
            posVersion: project[EDataKeys.POS_VERSION] ?? "",
        },
        mode: "onChange",
    });

    useEffect(() => {
        reset({
            oracleDealer: project[EDataKeys.VENDORS_ORACLE] || "",
            operaVersion: project[EDataKeys.OPERA_VERSION] || "",
            operaType: project[EDataKeys.OPERA_TYPE] || "",
            operaSC: project[EDataKeys.OPERA_SC] || "",
            operaMultiProperty: project[EDataKeys.OPERA_MULTI_PROPERTY] || "",
            operaConfigDate: project[EDataKeys.OPERA_CONFIG_DATE] ? new Date(project[EDataKeys.OPERA_CONFIG_DATE]) : null,
            operaCountDownDate: project[EDataKeys.OPERA_COUNTDOWN_DATE] ? new Date(project[EDataKeys.OPERA_COUNTDOWN_DATE]) : null,
            lockCountDownDate: project[EDataKeys.LOCK_COUNTDOWN_DATE] ?? false,
            ociEnvironment: project[EDataKeys.OCI_ENVIRONMENT] ?? "",
            vendorsPOS: project[EDataKeys.VENDORS_POS] ?? "",
            posVersion: project[EDataKeys.POS_VERSION] ?? "",
        });
    }, [project, reset])

    const {
        field: oracleDealer, //
    } = useController({
        name: "oracleDealer",
        control,
    });

    const {
        field: operaVersion, //
    } = useController({
        name: "operaVersion",
        control,
    });

    const {
        field: operaType, //
    } = useController({
        name: "operaType",
        control,
    });

    const {
        field: operaSC,
    } = useController({
        name: "operaSC",
        control,
    });

    const {
        field: operaMultiProperty,
    } = useController({
        name: "operaMultiProperty",
        control,
    });

    const {
        field: operaConfigDate,
    } = useController({
        name: "operaConfigDate",
        control,
    });

    const {
        field: operaCountDownDate,
    } = useController({
        name: "operaCountDownDate",
        control,
    });

    const {
        field: lockCountDownDate,
    } = useController({
        name: "lockCountDownDate",
        control,
    });

    const {
        field: ociEnvironment,
    } = useController({
        name: "ociEnvironment",
        control,
    });

    const {
        field: vendorsPOS,
    } = useController({
        name: "vendorsPOS",
        control,
    });

    const {
        field: posVersion,
    } = useController({
        name: "posVersion",
        control,
    });

    return (
        <form className={cx("form")}>
            <div className={cx("row")}>
                <label htmlFor="oracle-dealer">Oracle / Dealer</label>
                <Select
                    placeholder="Select Oracle / Dealer"
                    options={oracleDealerOptions.map((item) => ({
                        value: item,
                        label: item
                    }))}
                    onChange={(value) => {
                        oracleDealer.onChange(value);
                        updateProject([{ Id: project.Id, [EDataKeys.VENDORS_ORACLE]: value as string || "" }]);
                    }}
                    value={oracleDealer.value}
                    isClearable
                    id="oracle-dealer"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="opera-version">Opera Version</label>
                <Select
                    placeholder="Select Opera Version"
                    options={operaVersionOptions.map((item) => ({
                        value: item,
                        label: item
                    }))}
                    onChange={(value) => {
                        operaVersion.onChange(value);
                        updateProject([{ Id: project.Id, [EDataKeys.OPERA_VERSION]: value as string || "" }]);
                    }}
                    value={operaVersion.value}
                    isClearable
                    id="opera-version"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="opera-version">Opera Type</label>
                <Select
                    placeholder="Select Opera Type"
                    options={operaTypeOptions.map((item) => ({
                        value: item,
                        label: item
                    }))}
                    onChange={(value) => {
                        operaType.onChange(value);
                        updateProject([{ Id: project.Id, [EDataKeys.OPERA_TYPE]: value as string || "" }]);
                    }}
                    value={operaType.value}
                    isClearable
                    id="opera-version"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="oci-environment">OCI Environment</label>
                <Select
                    placeholder="Select OCI Environment"
                    options={OCIEnvironmentOptions.map((item) => ({
                        value: item,
                        label: item
                    }))}
                    onChange={(value) => {
                        ociEnvironment.onChange(value);
                        updateProject([{ Id: project.Id, [EDataKeys.OCI_ENVIRONMENT]: value as string || "" }]);
                    }}
                    value={ociEnvironment.value}
                    isClearable
                    id="oci-environment"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="opera-sc">Opera S&C</label>
                <Select
                    placeholder="Select Opera S&C"
                    options={operaSCOptions.map((item) => ({
                        value: item,
                        label: item
                    }))}
                    onChange={(value) => {
                        operaSC.onChange(value);
                        updateProject([{ Id: project.Id, [EDataKeys.OPERA_SC]: value as string || "" }]);
                    }}
                    value={operaSC.value}
                    isClearable
                    id="opera-sc"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="opera-multi-property">Opera Multi Property</label>
                <Select
                    placeholder="Select Opera Multi Property"
                    options={operaMultiPropertyOptions.map((item) => ({
                        value: item,
                        label: item
                    }))}
                    onChange={(value) => {
                        operaMultiProperty.onChange(value);
                        updateProject([{ Id: project.Id, [EDataKeys.OPERA_MULTI_PROPERTY]: value as string || "" }]);
                    }}
                    value={operaMultiProperty.value}
                    isClearable
                    id="opera-multi-property"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="opera-type">Opera Config Date</label>
                <DatePicker
                    onChange={(newDate) => {
                        operaConfigDate.onChange(newDate);
                        updateProject([{ Id: project.Id, [EDataKeys.OPERA_CONFIG_DATE]: (newDate as Date)?.toISOString() || null }]);
                    }}
                    date={operaConfigDate.value}
                    placeholder="Opera Config Date"
                    id="opera-type"
                />

            </div>
            <div className={cx("row")}>
                <label htmlFor="opera-countdown-date">Opera Countdown Date</label>
                <DatePicker
                    onChange={(newDate) => {
                        operaCountDownDate.onChange(newDate);
                        updateProject([{ Id: project.Id, [EDataKeys.OPERA_COUNTDOWN_DATE]: (newDate as Date)?.toISOString() || null }]);
                    }}
                    date={operaCountDownDate.value}
                    placeholder="Opera Countdown Date"
                    id="opera-countdown-date"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="lock-countdown-date">Lock CountDown Date</label>
                <SlideCheckbox
                    checked={lockCountDownDate.value}
                    onChange={(value) => {
                        lockCountDownDate.onChange(value);
                        updateProject([{ Id: project.Id, [EDataKeys.LOCK_COUNTDOWN_DATE]: value }]);
                    }}
                    id="lock-countdown-date"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="pos">POS</label>
                <Select
                    placeholder="POS"
                    options={posOptions.map((item) => ({
                        value: item,
                        label: item
                    }))}
                    onChange={(value) => {
                        vendorsPOS.onChange(value);
                        updateProject([{ Id: project.Id, [EDataKeys.VENDORS_POS]: value as string || "" }]);
                    }}
                    value={vendorsPOS.value}
                    isClearable
                    id="pos"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="pos-version">POS Version</label>
                
                <Input
                    name='pos-version'
                    onChange={(e) => {
                        posVersion.onChange(e.currentTarget.value);
                        debouncedUpdateProject([{ Id: project.Id, [EDataKeys.POS_VERSION]: e.currentTarget.value || "" }])
                    }}
                    value={posVersion.value}
                    className={cx("pos-version")}
                    placeholder="Type POS Version"
                />
            </div>
        </form>
    );
}

type FormValues = {
    oracleDealer: string,
    operaVersion: string,
    operaType: string,
    operaSC: string,
    operaMultiProperty: string,
    operaConfigDate: Date | null,
    operaCountDownDate: Date | null,
    lockCountDownDate: boolean,
    ociEnvironment: string,
    vendorsPOS: string,
    posVersion: string,
}

type Props = {
    project: TData
}

export default PMSFieldsForm;