import { standardColumns } from "../constants";
import { EDataKeys, TData } from "../types/TData";

export const createRows = (data: TData[]) => {
  return data.map((el) => {
    const newEl: TData = {
      [EDataKeys.ROW_ID]: 0,
      [EDataKeys.CITY_COUNTRY]: "",
      [EDataKeys.CONFIG_START_DATE]: new Date(),
      [EDataKeys.CONTRACT_TYPE]: "",
      [EDataKeys.FRANCHISEE]: "",
      [EDataKeys.IT_OPENING_DATE]: new Date(),
      [EDataKeys.ID]: "",
      [EDataKeys.LAST_3_PMC_SC_CALL_NOTES]: "",
      [EDataKeys.MARSHA]: "",
      [EDataKeys.MANAGER]: "",
      [EDataKeys.NUMBER_OF_ROOMS]: 0,
      [EDataKeys.OASIS_OPENING_DATE]: new Date(),
      [EDataKeys.OPERA_LEVEL]: 0,
      [EDataKeys.OPERA_VERSION]: "",
      [EDataKeys.OPERA_TYPE]: "",
      [EDataKeys.OWNER]: "",
      [EDataKeys.PRiMARY_IT_PROJECT_DIRECTOR_ABBREVIATION]: "",
      [EDataKeys.PROJECT_NAME]: "",
      [EDataKeys.PROJECT_TYPE]: "",
      [EDataKeys.RECORD_ID]: "",
      [EDataKeys.SC]: "",
      [EDataKeys.VENDORS_ORACLE]: "",
      [EDataKeys.CALL_NOTES]: "",
    };

    for (const key in el) {
      const col = standardColumns?.find((col) => col.id === key);
      if (col?.formatter) {
        newEl[key] = col.formatter(el[key], el);
      } else {
        newEl[key] = el[key];
      }
    }

    return newEl
  });
};
