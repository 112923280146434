const IconDownload = ({ fillColor = "inherit", size = "24px" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="DownloadSimple" clipPath="url(#clip0_366_3880)">
        <path
          id="Vector"
          d="M10 12.375V3.625"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M16.875 12.375V16.75C16.875 16.9158 16.8092 17.0747 16.6919 17.1919C16.5747 17.3092 16.4158 17.375 16.25 17.375H3.75C3.58424 17.375 3.42527 17.3092 3.30806 17.1919C3.19085 17.0747 3.125 16.9158 3.125 16.75V12.375"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M13.125 9.25L10 12.375L6.875 9.25"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_366_3880">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDownload;
