import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IRefetchState {
  value: boolean;
}

const initialState: IRefetchState = {
  value: false
};

export const refetchSlice = createSlice({
  name: "refetch",
  initialState,
  reducers: {
    setRefetch: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const { setRefetch } =
  refetchSlice.actions;

export default refetchSlice.reducer;
