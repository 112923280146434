/* eslint-disable jsx-a11y/anchor-is-valid */
// ### Configuration

import { handleDate } from "../helpers";
import { EEndpoints } from "./endpoints";

import { EDataKeys, TData } from "../types/TData";

export enum EKeyCallType {
  NAME = "Name",
  ACCESS = "Access",
  CUSTOM_FILTERS = "Custom Filters",
  DAYS = "Days",
  NOTE = "Note",
  ORACLE_VENDOR = "Oracle Vendor",
  UAT_VENDOR = "UAT Vendor",
  USER = "User",
  ID = "Id",
  ROW_ID = "@row.id",
  PROJECT_DIRECTORS = "Project Directors",
  CONTRACT_TYPE = "Contract Type",
  STATUS = "Status",
  UAT_STATUS = "UAT Status"
}

export interface ICallType {
  [EKeyCallType.NAME]: string;
  [EKeyCallType.CUSTOM_FILTERS]: string;
  [EKeyCallType.ACCESS]: string;
  [EKeyCallType.NOTE]: string;
  [EKeyCallType.USER]: string;
  [EKeyCallType.DAYS]: string;
  [EKeyCallType.ORACLE_VENDOR]?: string;
  [EKeyCallType.UAT_VENDOR]?: string;
  [EKeyCallType.UAT_STATUS]?: string;
  [EKeyCallType.PROJECT_DIRECTORS]?: string;
  [EKeyCallType.STATUS]?: string;
  [EKeyCallType.CONTRACT_TYPE]?: string;
  [EKeyCallType.ID]?: string;
  [EKeyCallType.ROW_ID]?: string;
}

export interface IColumn {
  id?: string;
  name: string;
  columnId: number;
  hiddenColumn?: boolean;
  doNotPullOutOfTheCOMPASS?: boolean;
  isDataJSX?: boolean;
  formatter?: (value: any, data: TData) => string | JSX.Element;
  isDate?: boolean;
  isNumeric?: boolean;
}


export const accesses = ["Public", "Private"];

export const posVersionTypes = [
  "",
  "ACT",
  "AdaptIT",
  "Best Solutions",
  "Bitsoft",
  "Current Link",
  "EEMC Kuwait",
  "Eurotel",
  "HRS",
  "Intelliway",
  "JDS",
  "KIT",
  "Novacom",
  "OSBC",
  "Protel",
  "SaudiCo",
  "SDS",
  "SUPDATA",
  "TechnoQ",
  "Van Hessen",
  "Unknown",
];

export enum ECallTypeNames {
  PMS = "PMS / S&C Call",
  POS = "POS Call",
  CLS = "CLS Update Notes",
  UAT = "UAT Call",
  ASN = "Application Services Notes",
  ESN = "Executive Summary Notes",
  IPN = "Internal Project Notes"

}
export const dataCallTypes: ICallType[] = [
  {
    [EKeyCallType.ID]: "Default-1",
    [EKeyCallType.NAME]: "PMS / S&C Call",
    [EKeyCallType.CUSTOM_FILTERS]: `[1, 2, 3, 24, 25, 6, 5, 26, 9, 10, 27, 11, 28, 14, 15, 29, 30, 31, 32, 18, 21]`,
    [EKeyCallType.ACCESS]: "Public",
    [EKeyCallType.NOTE]: "Last 3 PMS SC Call Notes",
    [EKeyCallType.USER]: "bernhard.schulz.privat@gmail.com",
    [EKeyCallType.ORACLE_VENDOR]: "true",
    [EKeyCallType.CONTRACT_TYPE]: "",
    [EKeyCallType.PROJECT_DIRECTORS]: "",
    [EKeyCallType.UAT_STATUS]: "",
    [EKeyCallType.UAT_VENDOR]: "",
    [EKeyCallType.STATUS]: "",
    [EKeyCallType.DAYS]: `[-30,730]`,
  },
  {
    [EKeyCallType.ID]: "Default-2",
    [EKeyCallType.NAME]: "POS Call",
    [EKeyCallType.CUSTOM_FILTERS]: `[1, 2, 3, 24, 25, 6, 5, 26, 9, 10, 27, 37, 16, 38, 30, 31, 32, 18, 21]`,
    [EKeyCallType.ACCESS]: "Public",
    [EKeyCallType.NOTE]: "Last 3 POS Call Notes",
    [EKeyCallType.USER]: "bernhard.schulz.privat@gmail.com",
    [EKeyCallType.ORACLE_VENDOR]: "true",
    [EKeyCallType.CONTRACT_TYPE]: "",
    [EKeyCallType.PROJECT_DIRECTORS]: "",
    [EKeyCallType.UAT_STATUS]: "",
    [EKeyCallType.UAT_VENDOR]: "",
    [EKeyCallType.STATUS]: "",
    [EKeyCallType.DAYS]: `[-30,730]`,
  },
  {
    [EKeyCallType.ID]: "Default-3",
    [EKeyCallType.NAME]: "CLS Update Notes",
    [EKeyCallType.CUSTOM_FILTERS]: `[1, 2, 3, 24, 25, 6, 5, 26, 9, 10, 27, 14, 16, 29, 17, 35, 34, 18, 21]`,
    [EKeyCallType.ACCESS]: "Public",
    [EKeyCallType.NOTE]: "Last 3 CLS Update Notes",
    [EKeyCallType.USER]: "bernhard.schulz.privat@gmail.com",
    [EKeyCallType.DAYS]: `[-30,730]`,
  },
  {
    [EKeyCallType.ID]: "Default-4",
    [EKeyCallType.NAME]: "UAT Call",
    [EKeyCallType.CUSTOM_FILTERS]: `[1, 2, 3, 24, 25, 6, 5, 26, 9, 10, 27, 33, 36, 18, 21]`,
    [EKeyCallType.ACCESS]: "Public",
    [EKeyCallType.NOTE]: "Last 3 UAT Call Notes",
    [EKeyCallType.USER]: "bernhard.schulz.privat@gmail.com",
    [EKeyCallType.UAT_VENDOR]: "true",
    [EKeyCallType.DAYS]: `[-30,730]`,
  },
  {
    [EKeyCallType.ID]: "Default-5",
    [EKeyCallType.NAME]: "Application Services Notes",
    [EKeyCallType.CUSTOM_FILTERS]: `[1, 2, 3, 24, 25, 6, 5, 26, 9, 10, 27, 11, 28, 14, 15, 29, 18, 21]`,
    [EKeyCallType.ACCESS]: "Public",
    [EKeyCallType.NOTE]: "Last 3 Application Services Notes",
    [EKeyCallType.USER]: "bernhard.schulz.privat@gmail.com",
    [EKeyCallType.DAYS]: `[-30,730]`,
  },
  {
    [EKeyCallType.ID]: "Default-6",
    [EKeyCallType.NAME]: "Executive Summary Notes",
    [EKeyCallType.CUSTOM_FILTERS]: `[1, 2, 3, 24, 25, 6, 5, 26, 9, 10, 27, 32, 30, 31, 18, 21]`,
    [EKeyCallType.ACCESS]: "Public",
    [EKeyCallType.NOTE]: "Last 3 Executive Summary Notes",
    [EKeyCallType.USER]: "bernhard.schulz.privat@gmail.com",
    [EKeyCallType.DAYS]: `[-30,730]`,
  },
  {
    [EKeyCallType.ID]: "Default-7",
    [EKeyCallType.NAME]: "Internal Project Notes",
    [EKeyCallType.CUSTOM_FILTERS]: `[1, 2, 3, 24, 25, 6, 5, 26, 9, 10, 27, 32, 30, 31, 18, 21]`,
    [EKeyCallType.ACCESS]: "Public",
    [EKeyCallType.NOTE]: "Last 3 Internal Project Notes",
    [EKeyCallType.USER]: "bernhard.schulz.privat@gmail.com",
    [EKeyCallType.DAYS]: `[-30,730]`,
  },
];

const generalColumns: IColumn[] = [
   {
    id: "Id",
    name: "Id",
    columnId: 2,
    hiddenColumn: true,
  },
  {
    id: "Record Id",
    name: "Record Id",
    columnId: 3,
    hiddenColumn: true,
  },
  {
    doNotPullOutOfTheCOMPASS: true,
    id: "Link",
    name: "Link",
    isDataJSX: true,
    formatter: (value, data) => {
      return (
        <div>
          <a
            target="_blank"
            href={`${EEndpoints.API_SECURE}&id=${data[EDataKeys.ROW_ID]}`}
            rel="noreferrer"
          >
            Open Project
          </a>
        </div>
      );
    },
    columnId: 4,
  },
  {
    id: "Project Name",
    name: "Name",
    columnId: 5,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Primary IT Project Director Abbreviation",
    name: "Prim. PD",
    columnId: 6,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Secondary IT Project Director Abbreviation",
    name: "Sec. PD",
    columnId: 7,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Active Target Completion Date",
    name: "Target Completion Date",
    columnId: 8,
    isDate: true,
    formatter: (cell: string | number | Date) => handleDate(cell),
  },
  {
    id: "Number of Rooms",
    name: "Rooms",
    columnId: 9,
    isNumeric: true,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Contract Type",
    name: "Contract",
    columnId: 10,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Opera Version",
    name: "Opera Version",
    columnId: 11,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "OASIS Stage",
    name: "OASIS Stage",
    columnId: 12,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Status",
    name: "Status",
    columnId: 13,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Opera S&C",
    name: "Opera S&C",
    columnId: 14,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Vendors Oracle",
    name: "Oracle Vendor",
    columnId: 15,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "POS Version",
    name: "POS Version",
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
    columnId: 16,
  },
  {
    id: "DAM Key Issues",
    name: "DAM Key Issues",
    columnId: 17,
    isDataJSX: true,
    formatter: (cell: string) => cell,
  },
  {
    doNotPullOutOfTheCOMPASS: true,
    id: "Call Notes",
    name: "Call Notes",
    columnId: 18,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    doNotPullOutOfTheCOMPASS: true,
    id: "PMS / S&C Notes",
    name: "PMS / S&C Notes",
    columnId: 19,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    doNotPullOutOfTheCOMPASS: true,
    id: "POS Notes",
    name: "POS Notes",
    columnId: 20,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    // ID will be set dynamically depending on callTypes
    name: "Last Notes",
    columnId: 21,
    isDataJSX: true,
    formatter: (cell) => {
      // Convert the string returned from the API into an array of objects so that they can be sorted (first entries from COMPASS by date descending, then entries from NOMAD)
      if (!cell) {
        return (
          <>
            <div className="last_call_notes"></div>
            <a href="#" className="expand">
              Show more
            </a>
            <a href="#" className="collapse">
              Collapse
            </a>
          </>
        );
      }
      let text = cell.toString();
      let splits = text
        .split(
          /(\d{4}-\d{2}-\d{2} .*?(?=NOMAD|\d{4}-\d{2}-\d{2} .|$)|NOMAD.*?(?=NOMAD|\d{4}-\d{2}-\d{2} .|$))/gs
        )
        .filter(Boolean);
      let objects = splits
        .map((s: string) => {
          let type = s.includes("NOMAD") ? "NOMAD" : "COMPASS";
          let dateMatch = s.match(/(\d{4}-\d{2}-\d{2})/);
          let date = type === "COMPASS" && dateMatch ? dateMatch[0] : null;
          let text = s.trim().replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags using regex
          if (type === "NOMAD") {
            text = text.replace("NOMAD", "<b>NOMAD</b>");
          } else if (type === "COMPASS") {
            let dateEnd = text.indexOf(":");
            text = `<b>${text.substring(0, dateEnd + 1)}</b>${text.substring(
              dateEnd + 1
            )}`;
          }
          return {
            type: type,
            date: date,
            text: text,
          };
        })
        .filter((o: { text: string | any[] }) => o.text.length > 0);
      objects.sort(
        (
          a: { type: string; date: string | number | Date },
          b: { type: string; date: string | number | Date }
        ) => {
          if (a.type === "COMPASS" && b.type === "COMPASS") {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          } else if (a.type === "COMPASS") {
            return -1;
          } else {
            return 1;
          }
        }
      );
      let html = objects
        .map(
          (o: { type: any; text: any }) =>
            `<div style="margin-bottom: 5px;" data-entry-type="${o.type}">${o.text}</div>`
        )
        .join("");
      return (
        <>
          <div className="text_cell">${html}</div>
          <a href="#" className="expand">
            Show more
          </a>
          <a href="#" className="collapse">
            Collapse
          </a>
        </>
      );
    },
  },
  {
    id: "OASIS Opening Date",
    name: "OASIS OD",
    columnId: 24,
    isDate: true,
    formatter: (cell: string | number | Date) => handleDate(cell),
  },
  {
    id: "IT Opening Date",
    name: "IT OD",
    columnId: 25,
    isDate: true,
    formatter: (cell: string | number | Date) => handleDate(cell),
  },
  {
    id: "City, Country",
    name: "Location",
    columnId: 26,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Project Type",
    name: "Project Type",
    columnId: 27,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Opera Config Date",
    name: "Opera Config Date",
    columnId: 29,
    isDate: true,
    formatter: (cell: string | number | Date) => handleDate(cell),
  },
  {
    id: "Franchisee",
    name: "Franchisee",
    columnId: 30,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Manager",
    name: "Manager",
    columnId: 31,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Owner",
    name: "Owner",
    columnId: 32,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "UAT Status",
    name: "UAT Status",
    columnId: 33,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "Countdown Duration",
    name: "Countdown Duration",
    columnId: 34,
    isNumeric: true,
    isDataJSX: true,
    formatter: (cell: string | number | Date) =>
      cell ? handleDate(new Date(cell)) : <div></div>,
  },
  {
    id: "Countdown Start Date",
    name: "Countdown Start",
    columnId: 35,
    isDate: true,
    formatter: (cell: string | number | Date) => handleDate(cell),
  },
  {
    id: "UAT Date",
    name: "UAT Date",
    columnId: 36,
    isDate: true,
    formatter: (cell: string | number | Date) => handleDate(cell),
  },
  {
    id: "Vendors POS",
    name: "Vendor",
    columnId: 37,
    isDataJSX: true,
    formatter: (value, data) => {
      return value;
    },
  },
  {
    id: "POS Config Start Date",
    name: "Config Start Date",
    columnId: 38,
    isDate: true,
    formatter: (cell: string | number | Date) => handleDate(cell),
  },
];

export const standardColumns: IColumn[] = [
  {
    id: "MARSHA",
    name: "MARSHA",
    columnId: 1,
    isDataJSX: true,
    formatter: (value, data) => {
      return (
        <div>
          <a
            target="_blank"
            href={`${EEndpoints.API_SECURE}&id=${data[EDataKeys.ROW_ID]}`}
            rel="noreferrer"
          >
            {value ? value : data["GD Project #"]}
          </a>
        </div>
      );
    },
  },
  ...generalColumns
];

/*

1	MARSHA
2	Id
3	Record Id
4	Link
5	Name
6	Prim. PD
7	Sec. PD
8	Target Completion Date
9	Rooms
10	Contract
11	Opera Ver.
12	OASIS Stage
13	Status
14	S&C
15	Oracle Vendor
16	POS Version
17	DAM Key Issues
18	Call Notes
19	PMS / S&C Notes
20	POS Notes
21	Last 3 Notes
22	Last 3 PMS / S&C Notes
23	Last 3 POS Notes
24	OASIS OD
25	IT OD
26	Location
27	Project Type
28	Opera Level
29	Config Start Date
30	Franchisee
31	Manager
32	Owner
33	UAT Status
34	Countdown Duration
35	Countdown Start
36	UAT Date
37  Vendors POS
38  POS Config Start Date

*/

// Standard Columns: 1, 2, 3, 24, 25, 6, 5, 26, 9, 10, 27

export const oracleVendors = [
  "Oracle Direct",
  "HRS",
  "Protel",
  "TechnoQ",
  "Van Hessen",
  "SaudiCo",
  "ACT",
  "AdaptIT",
  "Best Solutions",
  "Bitsoft",
  "Current Link",
  "EEMC Kuwait",
  "Eurotel",
  "Intelliway",
  "JDS",
  "KIT",
  "Novacom",
  "OSBC",
  "SDS",
  "SUPDATA",
];

export const uatVendors = [
  "14IP",
  "Acentic",
  "Esferize",
  "GuestTek",
  "Hoist",
  "I Media",
  "INQ / Africonnect",
  "InterTouch",
  "Mikenopa",
  "Navaya",
  "Non-compliant",
  "Reivernet",
  "The Cloud",
  "White Label",
  "WiFirst",
  "Wi-First",
  "Unknown",
];

export const uatStatusOptions = [
  "Not Started",
  "Scheduled",
  "Pre Test",
  "Testing in Progress",
  "Not Applicable",
  "Approved",
  "Failed"
];


export const delayOwnershipReasonOptions = [
  "Owner",
  "Hotel",
  "LSP",
  "Veridicum",
  "Equipment manufacturer"
];
export const oracleDealerOptions = [
  'ACT',
  'AdaptIT',
  'Best Solutions',
  'Bitsoft',
  'Current Link',
  'EEMC Kuwait',
  'Eurotel',
  'FO-Data',
  'HRS',
  'Intelliway',
  'JDS', 'KIT',
  'KIT Bahrain',
  'N/A, Residences',
  'Next Care',
  'Novacom',
  'Oracle dealer not yet selected',
  'Oracle Direct',
  'OSBC',
  'Protel',
  'SaudiCo',
  'SDS',
  'STL / Smart Technologies',
  'SUPDATA',
  'TechnoQ',
  'Unknown',
  'Van Hessen'
];

export const operaVersionOptions = [
  "APO 5.4",
  "OPO 5.4",
  "APO 5.6",
  "OPO 5.6"
];

export const operaTypeOptions = [
  "Premium",
  "Standard"
];

export const operaSCOptions = [
  "Yes",
  "No"
];

export const operaMultiPropertyOptions = [
  "Yes",
  "No"
];

export const OCIEnvironmentOptions = [
    "OCI1 (MAR4)",
    "OCI2 (HE09)",
    "OCI3 (HE12)",
    "OCI4 (HE20)",
    "OCI5 (HE21)",
    "OCI TBD"
]; 

export const posOptions = [
    "Factoro POS",
    "Infrasys",
    "Micros 9700 - local",
    "n/a",
    "No POS",
    "No POS - Outsourced",
    "Seltz",
    "Simphony - Hosted",
    "Simphony - Local",
    "Simphony Cloud",
    "Vectron",
    "Winrest POS",
    "Xenia"
];

export const delayReasonOptions = [
  "Awaiting UAT results",
  "Documentation delay",
  "HW Delay",
  "Outstanding cabling work",
  "Pending scheduled UAT"
]

export const roleOptions = [
  "Primary IT Project Director",
  "Secondary IT Project Director",
  "Senior Project Manager",
  "Manager Opening Projects",
  "Opening Tech Team",
  "Senior Manager Procurement",
  "Project Coordinator",
  "Director PMO",
  "Admin Assistant",
  "Senior Director Opening Projects"
]

export const departmentOptions = [
  "Commercial",
  "Operations",
  "IT"
];

export const projectPhaseOptions = [
  "Preliminary",
  "TSA",
  "PM",
  "Preopening / CD",
  "Handover"
]

export const contractTypeOptions = ["Managed", "Franchise"];

export const status = [
  "Not in Scope",
  "Not Started",
  "Preliminary",
  "Design",
  "Under Construction",
  "Pre-opening",
  "Open - Unbranded",
  "Open - Not Handed Over",
  "Open - Handed Over",
  "Project Closed",
  "On Hold / Cancelled",
];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
