import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ISidebarState {
  activeId: number | null
}

const initialState: ISidebarState = {
  activeId: null
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebar: (state, action: PayloadAction<{activeId: number | null }>) => {
      state.activeId = action.payload.activeId;
    },
  },
});

export const { setSidebar } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
