export enum EDataKeys {
  ROW_ID = "@row.id",
  CITY_COUNTRY = "City, Country",
  CONFIG_START_DATE = "Config Start Date",
  CONTRACT_TYPE = "Contract Type",
  FRANCHISEE = "Franchisee",
  IT_OPENING_DATE = "IT Opening Date",
  ID = "Id",
  LAST_3_PMC_SC_CALL_NOTES = "Last 3 PMS SC Call Notes",
  MARSHA = "MARSHA",
  MANAGER = "Manager",
  NUMBER_OF_ROOMS = "Number of Rooms",
  OASIS_OPENING_DATE = "OASIS Opening Date",
  OPERA_LEVEL = "Opera Level",
  OPERA_VERSION = "Opera Version",
  OPERA_TYPE = "Opera Type",
  OPERA_SC = "Opera S&C",
  OPERA_MULTI_PROPERTY = "Opera Multi Property",
  OPERA_CONFIG_DATE = "Opera Config Date",
  OPERA_COUNTDOWN_DATE = "Opera Countdown Date",
  OWNER = "Owner",
  PRiMARY_IT_PROJECT_DIRECTOR_ABBREVIATION = "Primary IT Project Director Abbreviation",
  PROJECT_NAME = "Project Name",
  PROJECT_TYPE = "Project Type",
  RECORD_ID = "Record Id",
  SC = "S&C",
  VENDORS_ORACLE = "Vendors Oracle",
  POS_VERSION = "POS Version",
  CALL_NOTES = "Call Notes",
  RELATED_SITE = "Related Site",
  UAT_STATUS = "UAT Status",
  UAT_DATE = "UAT Date",
  UAT_NOTE = "UAT Note",
  SUPPORT_CHARGE_STARTED = "Support Charge Started",
  DELAY_OWNERSHIP = "Delay Ownership",
  DELAY_REASON = "Delay Reason",
  LOCK_COUNTDOWN_DATE="Lock CountDown Date",
  OCI_ENVIRONMENT="OCI Environment",
  VENDORS_POS="Vendors POS",
}

export enum EDataManagerKeys {
  ROW_ID = "@row.id",
  ABREVIATION = "Abbreviation",
  EMAIL = "E-mail",
  FIRST_NAME = "First Name",
  FULL_NAME = "Full Name",
  LAST_NAME = "Last Name",
  NUMBER_OF_PROJECTS = "Number of Projects",
  USER = "User",
}

export enum EFilterKeys {
  DATA_IS_NULL = "dataIsNull",
  DATA_OPERATOR = "dataOperator",
  FILTER_KEY = "filterKey",
  FILTER_KEY_SECONDARY = "filterKeySecondary",
  FILTER_VALUE = "filterValue",
  OBJECT_ID = "objectId",
  GROUP = "group",
}

export type TSubmittedData = {
  [EDataKeys.ROW_ID]: number;
  [EDataKeys.ID]: string;
};

export type TData = {
  [EDataKeys.ROW_ID]: number;
  [EDataKeys.CITY_COUNTRY]: string;
  [EDataKeys.CONFIG_START_DATE]: Date;
  [EDataKeys.CONTRACT_TYPE]: string;
  [EDataKeys.FRANCHISEE]: string;
  [EDataKeys.IT_OPENING_DATE]: Date;
  [EDataKeys.ID]: string;
  [EDataKeys.LAST_3_PMC_SC_CALL_NOTES]: string;
  [EDataKeys.MARSHA]: any;
  [EDataKeys.MANAGER]: string;
  [EDataKeys.NUMBER_OF_ROOMS]: number;
  [EDataKeys.OASIS_OPENING_DATE]: Date;
  [EDataKeys.OPERA_LEVEL]: number;
  [EDataKeys.OPERA_TYPE]: string;
  [EDataKeys.OPERA_SC]?: string;
  [EDataKeys.OPERA_MULTI_PROPERTY]?: string;
  [EDataKeys.OPERA_VERSION]?: string;
  [EDataKeys.OPERA_CONFIG_DATE]?: string | null;
  [EDataKeys.OPERA_COUNTDOWN_DATE]?: string | null;
  [EDataKeys.OWNER]: string;
  [EDataKeys.PRiMARY_IT_PROJECT_DIRECTOR_ABBREVIATION]: string;
  [EDataKeys.PROJECT_NAME]: string;
  [EDataKeys.PROJECT_TYPE]: string;
  [EDataKeys.RECORD_ID]: string;
  [EDataKeys.SC]: string;
  [EDataKeys.VENDORS_ORACLE]: string;
  [EDataKeys.POS_VERSION]?: string;
  [EDataKeys.RELATED_SITE]?: string;
  [EDataKeys.CALL_NOTES]?: string;
  [EDataKeys.UAT_STATUS]?: string;
  [EDataKeys.UAT_DATE]?: string | null;
  [EDataKeys.UAT_NOTE]?: string;
  [EDataKeys.SUPPORT_CHARGE_STARTED]?: boolean;
  [EDataKeys.DELAY_OWNERSHIP]?: string;
  [EDataKeys.DELAY_REASON]?: string;
  [EDataKeys.LOCK_COUNTDOWN_DATE]?: boolean;
  [EDataKeys.OCI_ENVIRONMENT]?: string;
  [EDataKeys.VENDORS_POS]?: string;
};

export type TDataManager = {
  [EDataManagerKeys.ROW_ID]: number;
  [EDataManagerKeys.ABREVIATION]: string;
  [EDataManagerKeys.EMAIL]: string;
  [EDataManagerKeys.FIRST_NAME]: string;
  [EDataManagerKeys.FULL_NAME]: string;
  [EDataManagerKeys.LAST_NAME]: string;
  [EDataManagerKeys.NUMBER_OF_PROJECTS]: number;
  [EDataManagerKeys.USER]: string;
};

export type TFilter = {
  [EFilterKeys.DATA_IS_NULL]?: boolean;
  [EFilterKeys.DATA_OPERATOR]?: string;
  [EFilterKeys.FILTER_KEY]: string;
  [EFilterKeys.FILTER_KEY_SECONDARY]?: string;
  [EFilterKeys.FILTER_VALUE]?: string;
  [EFilterKeys.OBJECT_ID]: string;
  [EFilterKeys.GROUP]?: boolean;
};
