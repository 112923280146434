import React from "react";

import classnames from "classnames/bind";

import ReactPicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./DatePicker.global.scss";
import styles from "./DatePicker.module.scss";
import CalendarIcon from "../../assets/icons/CalendarIcon/CalendarIcon.com";
import {  IconChevronDown } from "../../assets/icons";


const cx = classnames.bind(styles);

const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long" };
const intlInstance = new Intl.DateTimeFormat("en-US", options);

const CustomInput = React.forwardRef(
    (
        { error, ...attributes }: React.InputHTMLAttributes<HTMLInputElement> & {error?: boolean}, //
        ref: React.ForwardedRef<HTMLInputElement>,
    ) => {
        return (
            <div className={cx("input")}>
                <label>
                    <span className={cx("icon")}>
                        <CalendarIcon />
                    </span>
                    <input
                        ref={ref}
                        type="text"
                        {...attributes}
                        className={cx({ "error": error })}
                    />
                </label>
            </div>
        );
    },
);

CustomInput.displayName = "CustomInput";

function Header({ date, onNextMonth, onPreviousMonth }: HeaderProps) {
    return (
        <div className={cx("header")}>
            <button onClick={(e) => { e.stopPropagation(); e.preventDefault(); onPreviousMonth() }} className={cx("month-control-icon", "month-control-icon-left")}>
                <IconChevronDown
                    size="24px"
                />

            </button>
            <button onClick={(e) => { e.stopPropagation(); e.preventDefault(); onNextMonth() }} className={cx("month-control-icon", "month-control-icon-right")}>
                <IconChevronDown
                    size="24px"
                />

            </button>
            <span className={cx("current-date")}>{intlInstance.format(date)}</span>
        </div>
    );
}

function convertUTCToLocalDate(date: Date | null) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
}

function convertLocalToUTCDate(date: Date | null) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
}


function DatePicker({
    date,
    placeholder = "Select Date",
    onChange,
    type,
    id,
    range = false,
    startDate,
    endDate,
    error
}: Props) {

    return (
        <div className={cx("date-picker")}>
            <ReactPicker
                renderCustomHeader={(params: ReactDatePickerCustomHeaderProps) => {
                    return (
                        <Header
                            date={params.monthDate}
                            onNextMonth={() => params.increaseMonth()}
                            onPreviousMonth={() => params.decreaseMonth()}
                        />
                    );
                }}
                customInput={<CustomInput error={error} />}
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    event.preventDefault();
                }}
                allowSameDay={false}
                selected={convertUTCToLocalDate(startDate||date || null)}
                startDate={convertUTCToLocalDate(startDate || null)}
                endDate={convertUTCToLocalDate(endDate || null)}
                selectsRange={range}
                onChange={date => {
                    Array.isArray(date) ?
                        onChange([convertLocalToUTCDate(date[0]), convertLocalToUTCDate(date[1])])
                        : onChange(convertLocalToUTCDate(date))
                }}
                dateFormat="dd.MM.yyyy"
                placeholderText={placeholder}
                isClearable
                showPopperArrow={false}
                className={cx({
                    error: type === "error",
                })}
                id={id}
                calendarClassName={cx("calendar")}
                dayClassName={() => {
                    return cx("day");
                }}
                wrapperClassName={cx("date-picker")}
            />
        </div>
    );
}

type Props= {
    date?: Date | null;
    startDate?: Date | null;
    endDate?: Date | null;
    onChange: (date: [Date | null, Date | null] | Date | null) => void;
    type?: "default" | "error";
    placeholder?: string;
    id?: string;
    range?: boolean;
    error?:boolean;
};

type HeaderProps = {
    date: Date;
    onNextMonth: () => void;
    onPreviousMonth: () => void;
};

export default DatePicker;
