import { useSelector } from "react-redux";
import React, { useState } from "react";
import classNames from "classnames/bind";

import homeImage from "../../assets/home-image.svg";

import styles from "./Home.module.scss";

const cx = classNames.bind(styles);

const Home = () => {
  return (
    <div className={cx("wrapper")}>
      <img src={homeImage} alt="" />
      <h1 className={cx("title")}>Please choose call type</h1>
      <p>Please select one of the call type in menu to see the results</p>
    </div>
  );
};

export default Home;
