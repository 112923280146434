import React, { useEffect, useMemo } from "react";

import styles from "./UATFieldsForm.module.scss";
import classNames from "classnames/bind";
import UATStatusSelect from "../selects/UATStatusSelect/UATStatusSelect";
import { useController, useForm } from "react-hook-form";
import { EDataKeys, TData } from "../../types/TData";
import DatePicker from "../DatePicker/DatePicker.com";
import SlideCheckbox from "../SlideCheckbox/SlideCheckbox.com";
import BasicTextarea from "../BasicTextarea/BasicTextarea.com";
import Select from "../SelectNew/Select";
import { delayOwnershipReasonOptions, delayReasonOptions } from "../../constants";
import { useUpdateProjectMutation } from "../../store/services";
import { debounce } from "../../helpers";

const cx = classNames.bind(styles);

function UATFieldsForm({ project }: Props) {
    const [updateProject] = useUpdateProjectMutation();
    const debouncedUpdateProject = useMemo(() => debounce(updateProject, 500),[updateProject]);
    const { control, reset } = useForm<FormValues>({
        defaultValues: {
            uatStatus: project[EDataKeys.UAT_STATUS] || "",
            uatDate: project[EDataKeys.UAT_DATE] ? new Date(project[EDataKeys.UAT_DATE]) : null,
            supportChargeStarted: project[EDataKeys.SUPPORT_CHARGE_STARTED] || false,
            uatNote: project[EDataKeys.UAT_NOTE] || "",
            delayOwnershipReason: project[EDataKeys.DELAY_OWNERSHIP] || "",
            delayReason: project[EDataKeys.DELAY_REASON] || ""
        },
        mode: "onChange",
    });

    useEffect(() => {
        reset({
            uatStatus: project[EDataKeys.UAT_STATUS] || "",
            uatDate: project[EDataKeys.UAT_DATE] ? new Date(project[EDataKeys.UAT_DATE]) : null,
            supportChargeStarted: project[EDataKeys.SUPPORT_CHARGE_STARTED] || false,
            uatNote: project[EDataKeys.UAT_NOTE] || "",
            delayOwnershipReason: project[EDataKeys.DELAY_OWNERSHIP] || "",
            delayReason: project[EDataKeys.DELAY_REASON] || ""
        });
    }, [project, reset])

    const {
        field: uatStatus, //
    } = useController({
        name: "uatStatus",
        control,
    });

    const {
        field: uatDate, //
    } = useController({
        name: "uatDate",
        control,
    });

    const {
        field: supportChargeStarted,
    } = useController({
        name: "supportChargeStarted",
        control,
    });

    const {
        field: uatNote,
    } = useController({
        name: "uatNote",
        control,
    });

    const {
        field: delayOwnershipReason,
    } = useController({
        name: "delayOwnershipReason",
        control,
    });

    const {
        field: delayReason,
    } = useController({
        name: "delayReason",
        control,
    });

    return (
        <form className={cx("uat-form")}>
            <div className={cx("row")}>
                <UATStatusSelect
                    onChange={(newValue) => {
                        uatStatus.onChange(newValue);
                        updateProject([{ Id: project.Id, [EDataKeys.UAT_STATUS]: newValue || "" }]);
                    }}
                    value={uatStatus.value}
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="uat-date">UAT Date</label>
                <DatePicker
                    onChange={(newDate) => {
                        uatDate.onChange(newDate);
                        updateProject([{ Id: project.Id, [EDataKeys.UAT_DATE]: (newDate as Date)?.toISOString() || null }]);
                    }}
                    date={uatDate.value}
                    placeholder="UAT Date"
                    id="uat-date"
                />

            </div>

            <div className={cx("row", "toggle")}>
                <SlideCheckbox
                    checked={supportChargeStarted.value}
                    onChange={(checked) => {
                        supportChargeStarted.onChange(checked);
                        updateProject([{ Id: project.Id, [EDataKeys.SUPPORT_CHARGE_STARTED]: checked }]);
                    }}
                    renderLabel={() => "Support Charge Started"}
                />
            </div>

            <div className={cx("row")}>
                <BasicTextarea
                    label="UAT Note"
                    onChange={(value) => {
                        uatNote.onChange(value);
                        debouncedUpdateProject([{ Id: project.Id, [EDataKeys.UAT_NOTE]: value || "" }])
                    }}
                    value={uatNote.value}
                    className={cx("uat-note")}
                    placeholder="Type UAT Note"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="delay-ownership-reason">Delay Ownership Reason</label>
                <Select
                    placeholder="Select Delay Ownership Reason"
                    options={delayOwnershipReasonOptions.map((item) => ({
                        value: item,
                        label: item
                    }))}
                    onChange={(value) => {
                        delayOwnershipReason.onChange(value);
                        updateProject([{ Id: project.Id, [EDataKeys.DELAY_OWNERSHIP]: value as string || "" }]);
                    }}
                    value={delayOwnershipReason.value}
                    isClearable
                    id="delay-ownership-reason"
                />
            </div>
            <div className={cx("row")}>
                <label htmlFor="delay-reason">Delay Reason</label>
                <Select
                    placeholder="Select Delay Reason"
                    options={delayReasonOptions.map((item) => ({
                        value: item,
                        label: item
                    }))}
                    onChange={(value) => {
                        delayReason.onChange(value);
                        updateProject([{ Id: project.Id, [EDataKeys.DELAY_REASON]: value as string || "" }]);
                    }}
                value={delayReason.value}
                isClearable
                id="delay-reason"
                />
            </div>
        </form>
    );
}

type FormValues = {
    uatStatus: string,
    uatDate: Date | null,
    uatNote: string,
    supportChargeStarted: boolean,
    delayOwnershipReason: string,
    delayReason: string,
}

type Props = {
    project: TData
}

export default UATFieldsForm;