import React, { useMemo } from "react";

import styles from "./PopupAddTask.module.scss";
import classNames from "classnames/bind";
import { IconClose } from "../../../assets/icons";
import { useController, useForm } from "react-hook-form";
import Select from "../../SelectNew/Select";
import { departmentOptions, projectPhaseOptions, roleOptions } from "../../../constants";
import BasicTextarea from "../../BasicTextarea/BasicTextarea.com";
import SlideCheckbox from "../../SlideCheckbox/SlideCheckbox.com";
import DatePicker from "../../DatePicker/DatePicker.com";
import { ProjectTask, useCreateProjectTaskMutation, useGetUsersQuery } from "../../../store/services";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useSelector } from "react-redux";
import { IState } from "../../../store";

const schema = Joi.object<ProjectTask>({
    description: Joi.string().trim().required().messages({
        "string.empty": "Description is not allowed to be empty",
    }),
    assignedToRole: Joi.string().optional(),
    assignee: Joi.string().optional(),
    projectPhase: Joi.string().optional(),
    department: Joi.string().optional(),
    personalTask: Joi.boolean(),
    startDate: Joi.date().required().messages({
        "any.required": "Dates are not allowed to be empty",
    }),
    endDate: Joi.date().required().messages({
        "any.required": "Dates are not allowed to be empty",
        "date.base": "End date is not allowed to be empty"
    }),
});

const cx = classNames.bind(styles);

function PopupAddTask({ onClose }: Props) {
    const { control, handleSubmit } = useForm<ProjectTask>({
        defaultValues: {
            personalTask: false,
            description: "",
        },
        mode: "onSubmit",
        resolver: joiResolver(schema)
    });

    const popups = useSelector((state: IState) => state.popupReducer);


    const { data: users } = useGetUsersQuery(undefined);

    const [createProject, { isLoading },] = useCreateProjectTaskMutation();

    const projectId = popups.popupAddTask.projectId;

    const {
        field: description,
        fieldState: { error: descriptionError },
    } = useController({
        name: "description",
        control,
    });

    const {
        field: assignedToRole
    } = useController({
        name: "assignedToRole",
        control,
    });

    const {
        field: assignee
    } = useController({
        name: "assignee",
        control,
    });

    const {
        field: projectPhase
    } = useController({
        name: "projectPhase",
        control,
    });


    const {
        field: department
    } = useController({
        name: "department",
        control,
    });

    const {
        field: personalTask
    } = useController({
        name: "personalTask",
        control,
    });


    const {
        field: startDate,
        fieldState: { error: startDateError }
    } = useController({
        name: "startDate",
        control,
    });

    const {
        field: endDate,
        fieldState: { error: endDateError }
    } = useController({
        name: "endDate",
        control,
    });

    const userOptions = useMemo(() => users?.map((user) =>
        ({ value: user["E-mail"], label: user["Full Name"] })), [users]);

    const datesError = startDateError?.message || endDateError?.message;

    const onValid = async (data: ProjectTask) => {
        await createProject([{...data, projectId}]);
        onClose();
    }
    return (
        <div className={cx("add-task")}>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(
                    onValid,
                )(e);
            }}>
                <button className={cx("close")} onClick={onClose}>
                    <IconClose size="16px" />
                </button>
                <h4>Add Task</h4>
                <div className={cx("row")}>
                    <BasicTextarea
                        className={cx("textarea")}
                        label="Description"
                        value={description.value}
                        onChange={(value) => description.onChange(value)}
                        type={descriptionError ? "error" : "default"}
                    />
                    {!!descriptionError?.message &&
                        <span className={cx("error")}>{descriptionError.message}</span>}
                </div>
                <div className={cx("row")}>
                    <label htmlFor="assigned-to-role">Assigned To Role</label>
                    <Select
                        options={roleOptions.map((item) => ({
                            value: item,
                            label: item
                        }))}
                        id="assigned-to-role"
                        value={assignedToRole.value || ""}
                        onChange={(value) => assignedToRole.onChange(value)}
                        isClearable
                        placeholder="Select Role"
                    />
                </div>
                <div className={cx("row")}>
                    <label htmlFor="assignee">Assignee</label>
                    <Select
                        options={userOptions?.map((item) => ({
                            value: item.value,
                            label: item.label
                        })) || []}
                        id="assignee"
                        value={assignee.value || ""}
                        onChange={(value) => assignee.onChange(value)}
                        isClearable
                        placeholder="Select Assignee"
                    />
                </div>
                <div className={cx("row")}>
                    <label htmlFor="project-phase">Project Phase</label>
                    <Select
                        options={projectPhaseOptions.map((item) => ({
                            value: item,
                            label: item
                        }))}
                        id="project-phase"
                        value={projectPhase.value || ""}
                        onChange={(value) => projectPhase.onChange(value)}
                        isClearable
                        placeholder="Select Project Phase"
                    />
                </div>
                <div className={cx("row")}>
                    <label htmlFor="department">Department</label>
                    <Select
                        options={departmentOptions.map((item) => ({
                            value: item,
                            label: item
                        }))}
                        id="department"
                        value={department.value || ""}
                        onChange={(value) => department.onChange(value)}
                        isClearable
                        placeholder="Select Department"
                    />
                </div>
                <div className={cx("row", "toggle")}>
                    <SlideCheckbox
                        checked={personalTask.value || false}
                        onChange={(checked) => personalTask.onChange(checked)}
                        renderLabel={() => "Personal Task"}
                    />
                </div>
                <div className={cx("row")}>
                    <label htmlFor="dates">Start / End Dates</label>
                    <DatePicker
                        startDate={startDate.value}
                        endDate={endDate.value}
                        range
                        onChange={(dates) => {
                            const [newStartDate, newEndDate] = dates as [Date | null, Date | null];
                            startDate.onChange(newStartDate);
                            endDate.onChange(newEndDate);
                        }}
                        error={!!datesError}
                        id="dates"
                    />
                    {(datesError) &&
                        <span className={cx("error")}>{datesError}</span>}
                </div>

                <button disabled={isLoading} type="submit" className={cx("save-task")}>
                    Save Task
                </button>
            </form>
        </div>)
}


type Props = {
    onClose: () => void;
}

export default PopupAddTask;