import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  IsFullWidthRowParams,
  RowClassParams,
  RowHeightParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import classNames from "classnames/bind";

import { IState } from "../../store";
import { setSidebar } from "../../store/services";
import { createHeaders } from "../../helpers";
import { EKeyCallType, ICallType } from "../../constants";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import styles from "./ProjectList.module.scss";

import { EDataKeys, TData } from "../../types/TData";
import useProjects from "./useProjects";

interface IProjectListProps {
  activeType: ICallType;
  autoHeight?: boolean;
}

const cx = classNames.bind(styles);

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

function addCheckboxListener(params: GridReadyEvent) {
  const checkbox = document.querySelector("input[type=checkbox]")! as any;
  checkbox.addEventListener("change", function () {
    params.api.setSuppressMoveWhenRowDragging(checkbox.checked);
  });
}

const ProjectList = ({
  activeType,
  autoHeight = false
}: IProjectListProps) => {

  const { rowData, activeRow: selectedRow, numberOfRecords, onNumberOfRecordsChange } = useProjects({ activeType });
  const colsFiltered = useSelector(
    (state: IState) => state.colsFilteredReducer[activeType?.[EKeyCallType.NAME]]
  );

   const newNotes = useSelector((state: IState) => state.newNotesReducer.newNotes
    .filter((el) => el.activeTypeName === activeType?.[EKeyCallType.NAME])); 

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    createHeaders(activeType, [])
  );

  const dispatch = useDispatch();


  const defaultColGroupDef = useMemo<Partial<ColGroupDef>>(() => {
    return { headerClass: cx("agg-header-group") };
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
      ...newNotes.length > 0 ? { filter: false } : { filter: true },
      sortable: true,
      unSortIcon: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      headerClass: cx("agg-header-cell"),
      wrapText: true,
      cellEditorPopup: true,
      flex: 1,
    };
  }, [rowData, newNotes]);

  const gridRef = useRef<AgGridReact>(null);


  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      addCheckboxListener(params);
    },
    [rowData]
  );

  const isFullWidthRow = useCallback((params: IsFullWidthRowParams) => {
    return params.rowNode.data.fullWidth;
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedRows: TData[] = gridRef?.current!.api?.getSelectedRows();
    const row: TData | undefined = rowData.find(
      (el) => el[EDataKeys.ROW_ID] === selectedRows[0]?.[EDataKeys.ROW_ID]
    );
    if (row) dispatch(setSidebar({ activeId: row[EDataKeys.ROW_ID] }));
  }, [rowData, dispatch]);

  const getRowHeight = useCallback(
    (params: RowHeightParams): number | undefined | null => {
      return 58;
    },
    []
  );

  const selectRow = useCallback(() => {
    if (gridRef.current && gridRef?.current!.api) {
      gridRef?.current!.api?.forEachNode((node) => {
        if (!selectedRow) {
          node.setSelected(false);
        } else {
          if (node.data[EDataKeys.ROW_ID] === selectedRow?.[EDataKeys.ROW_ID]) {
            node.setSelected(true);
          } else {
            node.setSelected(false);
          }
        }
      });
    }
  }, [selectedRow]);

  useEffect(() => {
    selectRow();
  }, [selectRow, selectedRow]);

  useEffect(() => {
    if (activeType)
      setColumnDefs(
        createHeaders(
          activeType,
          colsFiltered,
          !activeType[EDataKeys.ID]?.includes("Default")
        )
      );
  }, [colsFiltered, activeType]);

  const getRowClass = (params: RowClassParams) => {
    return cx("agg-row-cell");
  };

  const onModelUpdated = () => {
    onNumberOfRecordsChange(gridRef?.current?.api.getDisplayedRowCount() || null)
  }

  return (
    <div className={cx("project-bar")} id="projects">
      <div className={`${cx("project-director")} ag-theme-alpine`}>
        <AgGridReact<TData>
          ref={gridRef}
          getRowClass={getRowClass}
          rowData={rowData}
          rowDragManaged={true}
          animateRows
          domLayout={ autoHeight ? "autoHeight" : "normal" }
          defaultColDef={defaultColDef}
          defaultColGroupDef={defaultColGroupDef}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns
          isFullWidthRow={isFullWidthRow}
          rowSelection={"single"}
          colResizeDefault={"shift"}
          getRowHeight={getRowHeight}
          getRowId={(el) => el.data['@row.id'].toString()}
          onSelectionChanged={onSelectionChanged}
          onModelUpdated={onModelUpdated}
          suppressScrollOnNewData={true}
        ></AgGridReact>
      </div>
      {numberOfRecords && <div className={cx("total")}>Total Records: {numberOfRecords}</div>}
    </div>
  );
};

export default ProjectList;
