import classNames from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";
import { customAlphabet } from "nanoid";

import { SaveNotesButton, ResetChangesButton } from "../../components";

import { IState } from "../../store";
import { EKeyCallType } from "../../constants";
import {

  TProjectNotePayload,
  projectApi,
  setPopupDiscardNotesOpen,
  setRefetch,
  setSidebar,
} from "../../store/services";

import styles from "./SidebarNewNotes.module.scss";

import { EDataKeys } from "../../types/TData";
import { handleUnflat } from "../../helpers";
import { EFilterTypes } from "../../types/TFilter";

const cx = classNames.bind(styles);

const alphaBet = "0123456789";

const SidebarNewNotes = () => {

  const userEmail = useSelector((state: IState) => state.userReducer.email);
  const [handleSubmitNotes] = projectApi.endpoints.createProjectNote.useMutation();
  const activeType = useSelector(
    (state: IState) => state.activeTypeReducer.type
  );

  const [getSubmittedData] = projectApi.endpoints.getSubmittedData.useLazyQuery();
  // const [getProjects] = useLazyGetProjectsQuery();

  const filters = useSelector((state: IState) => state.filtersReducer);
  const dispatch = useDispatch();

  const newNotes = useSelector((state: IState) => state.newNotesReducer.newNotes
    .filter((el) => el.activeTypeName === activeType?.[EKeyCallType.NAME] || !el.activeTypeName));
  const isRefetch = useSelector((state: IState) => state.refetchReducer.value);

  const handleSave = async () => {
    if (userEmail) {
      const submissionId = customAlphabet(alphaBet, 20)();


      const submittedData: TProjectNotePayload[] = newNotes.map((el) => {

        return {
          "Related Site": el[EDataKeys.RELATED_SITE] || "",
          Description: el[EDataKeys.CALL_NOTES] || "",
          Type: activeType?.[EKeyCallType.NAME],
          "Submission Id": submissionId,
          "Submission Type": activeType?.[EKeyCallType.NAME],
          "Submitted By": userEmail,
          "Filters": [
            ...filters[EFilterTypes.DAYS] ?
              [`Days to Opening: ${filters[EFilterTypes.DAYS][0]} to ${filters[EFilterTypes.DAYS][1]}`] : [],
          ].join("; ")
        };
      });

      const chunks = handleUnflat(submittedData, 50);

      for (let chunk of chunks) {
        await handleSubmitNotes(chunk);
      }

      await getSubmittedData({ submissionId });

      handleReset();
    }
  };

  const handleReset = () => {
    dispatch(setRefetch(!isRefetch));
    dispatch(setSidebar({ activeId: null }));
  };

  return (
    <div className={cx("sidebar-wrapper", { open: newNotes.length > 0 })}>
      <div className={cx("sidebar-inner", { open: newNotes.length > 0 })}>
        <SaveNotesButton onSave={handleSave} />
        <ResetChangesButton onReset={() => dispatch(setPopupDiscardNotesOpen())} />
      </div>
    </div>
  );
};

export default SidebarNewNotes;
