import styles from "./Input.module.scss";
import classnames from "classnames/bind";
import React, { ForwardedRef } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

const cx = classnames.bind(styles);

const Input: React.FC<Props> = (
  (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const { label, name, error, message, value, ...rest } = props;
    return (
      <div className={cx("wrapper", { error })}>
        <input id={name} name={name} value={value || ""} {...rest} />
      </div>
    );
  }
);

Input.displayName = "Input";

type Props = {
  label?: string;
  name: string;
  placeholder?: string;
  error?: string | boolean;
  message?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
} & React.HTMLProps<HTMLInputElement>;

export default Input;
