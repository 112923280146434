import { useEffect, useMemo, useState } from "react";
import classNames from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";

import Select from "../Select/Select";

import { IState } from "../../store";
import { setRefetch } from "../../store/services";
import { EEndpoints, posVersionTypes } from "../../constants";

import styles from "./POSSelect.module.scss";

import { EDataKeys, TData } from "../../types/TData";

const cx = classNames.bind(styles);

interface IPOSSelectProps {
  data: TData;
}

interface IUpdateData {
  [EDataKeys.ID]: string;
  [EDataKeys.POS_VERSION]: string;
}

const POSSelect = ({ data }: IPOSSelectProps) => {
  const [version, setVersion] = useState(data[EDataKeys.POS_VERSION] || "");
  const [isRestart, setIsRestart] = useState(false);

  const dispatch = useDispatch();

  const isRefetch = useSelector((state: IState) => state.refetchReducer.value);

  useEffect(() => {
    setVersion(data[EDataKeys.POS_VERSION] || "");
    setIsRestart(!isRestart);
  }, [data]);

  const handleSendData = async (newData: IUpdateData) => {
    try {
      const response = await fetch(
        EEndpoints.API_ENDPOINT + "/Project/update.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newData),
        }
      );
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    const newData: IUpdateData = {
      [EDataKeys.ID]: data[EDataKeys.ID],
      [EDataKeys.POS_VERSION]: version,
    };
    const result = await handleSendData(newData);
    dispatch(setRefetch(!isRefetch));
  };

  return (
    <div className={cx("pos-version")}>
      <div className={cx("select-box")}>
        <Select
          options={posVersionTypes}
          onSelect={setVersion}
          startOption={version}
          restart={isRestart}
        />
      </div>

      <button className={cx("submit")} onClick={handleUpdate}>
        <span>Save</span>
      </button>
    </div>
  );
};

export default POSSelect;
