import classNames from "classnames/bind";
import { useSelector } from "react-redux";

import linkIcon from "../../../assets/icons/Link.svg";
import { IconClose } from "../../../assets/icons";

import { IState } from "../../../store";
import { EEndpoints } from "../../../constants";

import styles from "./PopupDownloadNewNotes.module.scss";

import { ENoteKeys } from "../../../types/TNote";

const cx = classNames.bind(styles);

interface IPopupDownloadNewNotesProps {
  onClose: () => void;
}

const PopupDownloadNewNotes = ({ onClose }: IPopupDownloadNewNotesProps) => {
  const newLinks = useSelector((state: IState) => state.newLinksReducer.value);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const [note] = newLinks;

  return (
    <div className={cx("popup")}>
      <button className={cx("close")} onClick={onClose}>
        <IconClose size="16px" />
      </button>
      <div className="popup-inner">
        <h3>Share link to report:</h3>
        {note && <div className={cx("call-note-history-wrapper")}>
          <div>
            <div
              key={`single-${note[ENoteKeys.ID]}`}
              className={cx("call-note-history-box")}
            >
              <img src={linkIcon} alt="" />
              <div className={cx("call-note-history-link")}>{`${EEndpoints.API_ENDPOINT
                }/Project%20Note/Project%20Note%20Report/document?id=${note[ENoteKeys.ROW_ID]
                }`}</div>
              <button
                onClick={() =>
                  handleCopy(
                    `${EEndpoints.API_ENDPOINT
                    }/Project%20Note/Project%20Note%20Report/document?id=${note[ENoteKeys.ROW_ID]
                    }`
                  )
                }
              >
                Copy Link
              </button>
            </div>
            <a
              target="_blank"
              href={`${EEndpoints.API_ENDPOINT
                }/Project%20Note/Project%20Note%20Report/document?id=${note[ENoteKeys.ROW_ID]
                }`}
              rel="noreferrer"
            >
              <button className={cx("download", "margin")}>
                Download Call Note Report
              </button>
            </a>
          </div>
        </div>
      }
      </div>
    </div>
  );
};

export default PopupDownloadNewNotes;
