const config =  {
    APP_URL: process.env.REACT_APP_BASE_URL ?? "http://localhost:3000",
    PROJECT_NOTES_TABLE_NAME: process.env.REACT_APP_PROJECT_NOTES_TABLE_NAME ?? "Project Note - Test table",
    EMAIL_RECIPIENT: process.env.REACT_APP_EMAIL_RECIPIENT ?? "",
    CC_RECIPIENT: process.env.REACT_APP_CC_RECIPIENT ?? "",
    NODE_ENV: process.env.NODE_ENV,
    APP_ENV: process.env.REACT_APP_ENV ?? "development",
    APP_TOKEN: process.env.REACT_APP_TOKEN ?? "mb3OXuihYk2KCWYmC8pMBw8TZUS7VxGJc"
}

export default config;