import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EKeyCallType, dataCallTypes, standardColumns } from '../../constants';

export type IColsFilteredState = Record<string, number[]>

export const colsFilteredInitialState: IColsFilteredState = dataCallTypes.reduce((acc, item) => {
  acc[item[EKeyCallType.NAME]] = standardColumns.filter((col) => 
  !JSON.parse(item[EKeyCallType.CUSTOM_FILTERS]).includes(col.columnId)).map((col) => col.columnId);
  return acc;
}, {}); 

export const colsFilteredSlice = createSlice({
  name: 'colsFiltered',
  initialState: colsFilteredInitialState,
  reducers: {
    setColsFiltered: (state, action: PayloadAction<{activeType: string, filteredColumns: number[]}>) => {
      state[action.payload.activeType] = action.payload.filteredColumns;
    },
  },
})

export const { setColsFiltered } = colsFilteredSlice.actions

export default colsFilteredSlice.reducer