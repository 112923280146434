import { useState } from "react";
import classNames from "classnames/bind";

import { IconClose } from "../../assets/icons";

import styles from "./Multiselect.module.scss";

const cx = classNames.bind(styles);

interface ISelectProps {
  startOption?: string;
  options: string[];
  onSelect: (value: string[]) => void;
  onRemove?: (value: string) => void;
  onReset?: () => void;
  disabled?: boolean;
}

const Multiselect = ({
  startOption,
  options,
  onSelect,
  onReset,
  disabled,
  onRemove,
}: ISelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeOptions, setActiveOptions] = useState<string[]>([]);
  const [filterOptions, setFilterOptions] = useState<string[]>(options);

  const toggleIsOpen = () => {
    if (!disabled) setIsOpen(!isOpen);
  };

  const handleOption = (value: string) => {
    setActiveOptions([...activeOptions, value]);
    setFilterOptions(filterOptions.filter((el) => el !== value));
    onSelect([...activeOptions, value]);
  };

  const handleRemoveOption = (value: string) => {
    setActiveOptions(activeOptions.filter((el) => el !== value));
    setFilterOptions([...filterOptions, value]);
    if (onRemove) onRemove(value);
  };

  const handleReset = () => {
    setActiveOptions([]);
    setFilterOptions(options);
    if (onReset) onReset();
  };

  return (
    <div className={cx("box")}>
      {onReset && activeOptions.length > 0 && (
        <button className={cx("reset")} onClick={handleReset}>
          <IconClose size="16px" />
        </button>
      )}
      <div
        className={cx("nice-select", isOpen && "open", disabled && "disabled")}
        onClick={toggleIsOpen}
      >
        {activeOptions.length > 0 ? (
          <div className={cx("chip-wrapper")}>
            {activeOptions.map((opt, i) => (
              <div key={`opt-${opt}-${i}`} className={cx("chip")}>
                {opt}
                <button onClick={() => handleRemoveOption(opt)}>
                  <IconClose size="8px" />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <span>{startOption}</span>
        )}
        <ul className={cx("list", isOpen && "open")}>
          {filterOptions.length > 0 ? (
            filterOptions.map((el) => (
              <li
                key={el}
                className={cx("option")}
                onClick={() => handleOption(el)}
              >
                {el}
              </li>
            ))
          ) : (
            <li className={cx("option")}>No options</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Multiselect;
