import classNames from "classnames/bind";
import { useSelector } from "react-redux";

import { IState } from "../../store";

import styles from "./Loader.module.scss";
import DotSpinner from "../DotSpinner/DotSpinner.com";

const cx = classNames.bind(styles);

const Loader = () => {
  const isOpen = useSelector((state: IState) => state.loaderReducer.value);
  return (
    <div className={cx("loader-wrapper", { open: isOpen })}>
      <div className={cx("loader-inner")}>
       { isOpen && <DotSpinner /> }
      </div>
    </div>
  );
};

export default Loader;
