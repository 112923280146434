import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICallType } from "../../constants";
import { projectApi } from "./project";

export interface IActiveTypeState {
  type: ICallType | null;
}

const initialState: IActiveTypeState = {
  type: null
};

export const typeSlice = createSlice({
  name: "type",
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<ICallType>) => {
      state.type = action.payload;
    },
  },
});

export const {
  setType,
} = typeSlice.actions;

export default typeSlice.reducer;
