import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EDataKeys } from '../../types/TData'
import { projectApi } from './project'

export type NewNote = {
  [EDataKeys.ROW_ID]: number,
  [EDataKeys.ID]: string,
  [EDataKeys.CALL_NOTES]: string,
  [EDataKeys.MARSHA]: string,
  ["Related Site"]?: string,
  activeTypeName: string
}
export interface INewNotesState {
  newNotes: NewNote[]
}

const initialState: INewNotesState = {
  newNotes: []
}

export const newNotesSlice = createSlice({
  name: 'newNotes',
  initialState,
  reducers: {
    setNewNote: (state, action: PayloadAction<{ newNote: NewNote }>) => {
      const foundIndex = state.newNotes.findIndex(item => item[EDataKeys.ROW_ID] === action.payload.newNote[EDataKeys.ROW_ID] 
        && item.activeTypeName === action.payload.newNote.activeTypeName);
      const value = action.payload.newNote['Call Notes']?.trim();
      if (foundIndex !== -1) {
        if (value) state.newNotes[foundIndex] = { ...state.newNotes[foundIndex] || {}, ...action.payload.newNote, [EDataKeys.CALL_NOTES]: value };
        else state.newNotes.splice(foundIndex, 1);
      } else {
        if (value) state.newNotes.push({ ...action.payload.newNote, [EDataKeys.CALL_NOTES]: value});
      }
    },
    resetNewNotes: (state, action: PayloadAction<{activeTypeName: string}>) => {
      state.newNotes = state.newNotes.filter(item => !item.activeTypeName || item.activeTypeName !== action.payload.activeTypeName);

      return state;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(projectApi.endpoints.getSite.matchFulfilled, (state, { payload, meta }) => {
      const foundIndex = state.newNotes.findIndex(note => note[EDataKeys.MARSHA] === meta.arg.originalArgs);

      if (foundIndex !== -1) {
        state.newNotes[foundIndex]["Related Site"] = payload[0]?.["Related Site"];
      }
    }).addMatcher(projectApi.endpoints.getSubmittedData.matchFulfilled, (state) => {
      state = initialState;
      return state;
    })

  },
})

export const { resetNewNotes, setNewNote } = newNotesSlice.actions

export default newNotesSlice.reducer