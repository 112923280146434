import React from "react";

import classNames from "classnames/bind";

import styles from "./PopupDiscardNotes.module.scss";
import { IconClose } from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { resetNewNotes, setPopupDiscardNotesClose, setSidebar } from "../../../store/services";
import { IState } from "../../../store";
import { EKeyCallType } from "../../../constants";

const cx = classNames.bind(styles);

interface IPopupDiscardNotesProps {
  onClose: () => void;
}

const PopupDiscardNotes = ({ onClose }: IPopupDiscardNotesProps) => {
  const dispatch = useDispatch();

  const activeType = useSelector(
    (state: IState) => state.activeTypeReducer.type,
  );

  const handleClick = () => {
    dispatch(resetNewNotes({ activeTypeName: activeType?.[EKeyCallType.NAME] || ""}));
    dispatch(setSidebar({ activeId: null }))
    dispatch(setPopupDiscardNotesClose())
  }
  return (
    <div className={cx("popup-discard-notes")}>
      <button className={cx("close")} onClick={onClose}>
        <IconClose size="16px" />
      </button>
      <h4>Discard notes</h4>

      <div className={cx("question")}>
        Do you really want to discard notes?
      </div>
      <div className={cx("button-wrapper")}>
        {
          <button
            className={cx("confirm-discard-notes-button")}
            onClick={handleClick}
          >
            <span>Discard</span>
          </button>
        }
      </div>
    </div>
  );
};

export default PopupDiscardNotes;
