import { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";

import { IconClose } from "../../../assets/icons";

import { colsFilteredInitialState, setColsFiltered, useUpdateCustomCallTypeMutation } from "../../../store/services";
import { EKeyCallType, IColumn, dataCallTypes, standardColumns } from "../../../constants";

import styles from "./PopupColumnSelector.module.scss";
import { IState } from "../../../store";

const cx = classNames.bind(styles);

interface IPopupColumnSelectorProps {
  onClose: () => void;
}

const PopupColumnSelector = ({ onClose }: IPopupColumnSelectorProps) => {
  const activeType = useSelector(
    (state: IState) => state.activeTypeReducer.type
  );
  const customCallType = !dataCallTypes.find(callType => callType.Id === activeType?.Id);
  const colsFiltered = useSelector((state: IState) => state.colsFilteredReducer[activeType?.Name || ""]);

  let filteredColumnsInitial = colsFiltered;
  if (customCallType && activeType) {
    const currentSelected = JSON.parse(activeType?.[EKeyCallType.CUSTOM_FILTERS] || "[]")
    filteredColumnsInitial = standardColumns
      .filter(column => !currentSelected.includes(column.columnId))
      .map(column => column.columnId);
  }
  const [colsFilteredCurrent, setColsFilteredCurrent] = useState<number[]>(filteredColumnsInitial || []);
  const dispatch = useDispatch();

  const [updateCallType] = useUpdateCustomCallTypeMutation();


  const handleFilter = (id: number) => {
    if (colsFilteredCurrent.includes(id)) {
      setColsFilteredCurrent(colsFilteredCurrent.filter((el) => el !== id));
    } else setColsFilteredCurrent([...colsFilteredCurrent, id]);
  };

  const handleSave = () => {
    if (customCallType) updateCallType({
      id: activeType?.Id || "",
      payload: {
        [EKeyCallType.CUSTOM_FILTERS]: JSON.stringify(
          columnsFiltered
            .filter(col => !colsFilteredCurrent.includes(+col.columnId))
            .map(col => col.columnId)
        )
      }
    })

    if (activeType?.Name) {
      dispatch(setColsFiltered({ activeType: activeType?.Name, filteredColumns: colsFilteredCurrent }))
    }
  };

  const handleReset = () => {
    let filteredColumns: number[] = [];
    if (activeType?.Name) {
      
      filteredColumns = colsFilteredInitialState[activeType?.Name];
      dispatch(setColsFiltered({ activeType: activeType?.Name, filteredColumns: filteredColumns }));
    }
    setColsFilteredCurrent(filteredColumns);

    if (customCallType) updateCallType({
      id: activeType?.Id || "",
      payload: {
        [EKeyCallType.CUSTOM_FILTERS]: JSON.stringify((columnsFiltered).map(col => col.columnId))
      }
    })
  };

  let columnIds: number[] = standardColumns.map(col => col.columnId);

  if (!customCallType && activeType) {
    const defaultColumnIds = JSON.parse(activeType[EKeyCallType.CUSTOM_FILTERS]);
    columnIds.sort((a, b) => {
      if (defaultColumnIds.includes(a) && !defaultColumnIds.includes(b)) return -1;
      if (!defaultColumnIds.includes(a) && defaultColumnIds.includes(b)) return 1;

      return 0;
    })
  }

  const columns: any = columnIds.map((col: number) => standardColumns.find((el) => el.columnId === col));

  let columnsFiltered: IColumn[] = columns
    ? columns.filter((el: IColumn) => !el?.hiddenColumn && !el.doNotPullOutOfTheCOMPASS)
    : [];

  if (!activeType?.[EKeyCallType.ID]?.includes("Default"))
    columnsFiltered = columnsFiltered.filter((el) => el.name !== "Last Notes");


  return (
    <div className={cx("column-selector")}>
      <button className={cx("close")} onClick={onClose}>
        <IconClose />
      </button>
      <h4>Column Selector</h4>
      <div className={cx("checkbox-wrapper")}>
        {columnsFiltered.map((el) => (
          <div
            className={cx(
              "column-checkbox",
              {
                checked: el && !colsFilteredCurrent?.includes(el.columnId)
              })}
            key={`column-selector-${el?.id}`}
            onClick={() => handleFilter(el?.columnId || 0)}
          >
            {el?.name}
          </div>
        ))}
      </div>
      <div className={cx("buttons-wrapper")}>
        <button className={cx("save-columns")} onClick={handleSave}>
          Save Columns
        </button>
        <button className={cx("reset-columns")} onClick={handleReset}>
          Reset Columns
        </button>
      </div>
    </div>
  );
};

export default PopupColumnSelector;
