import classNames from "classnames/bind";

import styles from "./SaveNotesButton.module.scss";

interface SaveNotesButtonPeops {
  onSave: () => void;
}

const cx = classNames.bind(styles);

const SaveNotesButton = ({ onSave }: SaveNotesButtonPeops) => {
  return (
    <button
      className={cx("save_callnotes")}
      onClick={onSave}
    >
      <span>Submit all notes</span>
    </button>
  );
};

export default SaveNotesButton;
