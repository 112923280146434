import React, { ForwardedRef, forwardRef, useRef } from "react";
import ReactSelect, { ActionMeta, CSSObjectWithLabel, MenuProps, MultiValue, MultiValueProps, SingleValue, ValueContainerProps, components } from "react-select";
import classnames from "classnames/bind";

import styles from "./Select.module.scss";
import { IconClose } from "../../assets/icons";

const cx = classnames.bind(styles);
const selectAllOption = {
    value: "<SELECT_ALL>",
    label: "Select All"
};

const deselectAllOption = {
    value: "<DESELECT_ALL>",
    label: "Deselect All"
}
const Select = forwardRef((props: Props, ref: ForwardedRef<HTMLSelectElement>) => {
    const { error, options, isClearable = false, isMulti = false, id, closeMenuOnSelect = true, ...rest } = props;

    const currentValue = isMulti ?
        options.filter(item => props.value.includes(item.value))
        :
        options.find(item => item.value === props.value) || null;

    const valueRef = useRef(currentValue);
    valueRef.current = currentValue;

    const isSelectAllSelected = () =>
   Array.isArray(valueRef?.current) && valueRef.current.length === props.options.length;

    const getOptions = () => {

        return [
            ...isMulti ? [isSelectAllSelected() ? deselectAllOption : selectAllOption] : [],
            ...options
        ]
    }



    const onChange = (value: SingleValue<Option> | MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
        if (Array.isArray(value)) {
            const { action, option } = actionMeta;
            if (action === "select-option" && option?.value === selectAllOption.value)
                return props.onChange(options.map(item => item.value));
  
            if (action === "select-option" && option?.value === deselectAllOption.value) {
                return props.onChange([]);
            }
            return props.onChange(value.map(value => value?.value));
        } 
        
        return props.onChange(((value as SingleValue<Option>)?.value || (value as SingleValue<Option>)?.value === "")
                ? (value as SingleValue<Option>)?.value : null)
    }

    return (
        <div className={cx("wrapper")}>
            <ReactSelect<Option, typeof isMulti>
                {...rest}
                isMulti={isMulti}
                isClearable={isClearable}
                options={getOptions()}
                inputId={id}
                closeMenuOnSelect={closeMenuOnSelect}
                styles={{
                    control: (baseStyles: CSSObjectWithLabel, state: any) => ({
                        ...baseStyles,
                        borderColor: error ? "#DF715F" : state.isFocused ? "#ADB4BD" : "#D9DDE2",
                        outline: "none",
                        borderRadius: "8px",
                        fontSize: "0.875rem",
                        boxShadow: state.isFocused ? "none" : "none",
                        height: isMulti ? "auto" : "40px",
                        zIndex: 11,
                        borderBottomLeftRadius: state.menuIsOpen ? 0 : "8px",
                        borderBottomRightRadius: state.menuIsOpen ? 0 : "8px",
                        paddingLeft: "calc(0.5rem - 1px)",
                        "&:hover": {
                            borderColor: error ? "#DF715F" : state.isFocused ? "#ADB4BD" : "#D9DDE2",
                            boxShadow: state.isFocused ? "none" : "none",
                        },
                    }),
                    placeholder: (base: CSSObjectWithLabel) => ({
                        ...base,
                        fontSize: "0.875rem",
                        color: "#ADB4BD",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                    }),
                    menuList: (base: CSSObjectWithLabel) => ({
                        ...base,
                        marginTop: 0,
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                        border: "1px solid #ADB4BD",
                        boxShadow: "none",
                        transition: "all .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out",
                        transformOrigin: "50% 0",
                    }),
                    menu: (base: CSSObjectWithLabel) => {
                        return {
                            ...base,
                            background: "#fff",
                            marginTop: "-2px",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            zIndex: 20,
                            // width: "calc(100% - 2px)",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                            borderColor: "#ADB4BD!important",
                            boxShadow: "none",
                            border: "none",
                            // marginLeft: "1px"
                        };
                    },
                    option: (base: CSSObjectWithLabel, { isDisabled, isSelected }: { isDisabled: boolean, isSelected: boolean }) => {
                        return {
                            ...base,
                            backgroundColor: "#fff",
                            padding: "12px",
                            fontSize: "0.875rem",
                            color: isSelected ? "#202020" : "#ADB4BD",
                            cursor: isDisabled ? "not-allowed" : "default",
                            "&:active": {
                                background: "#fff",
                            },
                            "&:hover": {
                                color: "#202020",
                            },
                        };
                    },
                    valueContainer: (base, props) => {
                        return {
                            ...base,
                            flexWrap: "nowrap",
                            padding: Array.isArray(props.selectProps.value) && props.selectProps.value.length ? 0 : "2px 8px",
                        }
                    },
                    multiValue: (base: CSSObjectWithLabel) => {
                        return {
                            ...base,
                            paddingLeft: 0,
                            padding: 0,
                        }
                    }
                }}
                isSearchable={false}
                components={{
                    MultiValue: MultiValueComp,
                    MultiValueRemove: () => null,
                    Menu,
                    IndicatorSeparator: () => null,
                    ClearIndicator: ({ ...clearIndicatorProps }) => (
                        <components.ClearIndicator
                            {...clearIndicatorProps}
                        >
                            {clearIndicatorProps.children}
                            <div style={{ stroke: "#4a7a8a", cursor: "pointer" }}>

                                <IconClose size="16" />
                            </div>
                        </components.ClearIndicator>
                    ),
                    DropdownIndicator: (dropdownIndicatorProps): any => (
                        <svg
                            style={{
                                transform: dropdownIndicatorProps.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0)",
                                transitionProperty: "all",
                                transitionDuration: ".15s",
                                transitionTimingFunction: "ease-in-out",
                                marginRight: "1rem",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none">
                            <g id="CaretDown" clipPath="url(#clip0_150_3041)">
                                <path id="Vector" d="M13 6L8 11L3 6" stroke="#4A7A8A" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_150_3041">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>),
                }}
                value={currentValue}
                ref={ref as any}
                onChange={onChange}
                hideSelectedOptions={false}
            />
            {error && <div className={cx("error")}>{error}</div>}
        </div >
    );
});

const MultiValueComp = ({ index, getValue }: MultiValueProps<Option>): any =>  !index && `${getValue().length} items selected`;

// const ValueContainer = (props: ValueContainerProps<Option>) => {
//     const value = props.selectProps.value;
//     console.log(props);
//     if (Array.isArray(value) && value?.length > 3) {
//         return (
//             <components.ValueContainer{...props} >
//                 <div className={styles.valueContainer}>{value.length} Selected</div>
//             </components.ValueContainer>
//         )
//     }
//     return <components.ValueContainer{...props} />
// }

const Menu = (menuProps: MenuProps<Option>) => <components.Menu {...menuProps} className={cx("menu")} />;

Select.displayName = "Select";

type Option = {
    value: string,
    label: string

}
type Props = {
    value: string | string[]
    onChange: (value: string | null | undefined | string[]) => void,
    onBlur?: () => void,
    options: Option[],
    isClearable?: boolean,
    placeholder?: string,
    error?: string,
    isMulti?: boolean,
    isDisabled?: boolean,
    id?: string;
    closeMenuOnSelect?: boolean;
};

export default Select;
