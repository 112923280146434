import classNames from "classnames/bind";

import styles from "./PrimaryButton.module.scss";

interface PrimaryButtonProps {
    onClick: () => void;
    title: string;
    disabled?: boolean;
    loading?: boolean;
}

const cx = classNames.bind(styles);

const PrimaryButton = ({ onClick, title, disabled, loading }: PrimaryButtonProps) => {
    return (
        <button
            className={cx("primary-button", { 'disabled': (disabled || loading) })}
            onClick={() => (disabled || loading) ? null : onClick()}
        >
            <span>{title}</span>
        </button>
    );
};

export default PrimaryButton;
