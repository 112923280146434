export enum ENoteKeys {
  NOTES_IN_SUBMISSION = "# Notes in Submission",
  ROW_ID = "@row.id",
  DATE_CREATED = "Date Created",
  ID = "Id",
  SUBMITTED_BY = "Submitted By",
  TYPE = "Type",
  KEY = "Key",
  SUBMISSION_ID = "Submission Id",
}

export type TNote = {
  [ENoteKeys.NOTES_IN_SUBMISSION]: number;
  [ENoteKeys.ROW_ID]: number;
  [ENoteKeys.DATE_CREATED]: Date;
  [ENoteKeys.ID]: string;
  [ENoteKeys.SUBMITTED_BY]: string;
  [ENoteKeys.TYPE]: string;
  [ENoteKeys.SUBMISSION_ID]: string;
};

export enum EProjectNoteKeys {
  ROW_ID = "@row.id",
  DATE_CREATED = "Date Created",
  DESCRIPTION = "Description",
  RECORD_OWNER = "Record Owner",
  NOMAD_IMPORT = "NOMAD Import",
  SUBMITTED_BY = "Submitted By",
  ID = "Id",
  SUBMISSION_ID = "Submission Id"
}

export type TProjectNote = {
  [EProjectNoteKeys.ROW_ID]: number,
  [EProjectNoteKeys.DATE_CREATED]: Date,
  [EProjectNoteKeys.DESCRIPTION]: string,
  [EProjectNoteKeys.RECORD_OWNER]: string,
  [EProjectNoteKeys.NOMAD_IMPORT]: boolean,
  [EProjectNoteKeys.ID]: string
  [EProjectNoteKeys.SUBMISSION_ID]: string;
}