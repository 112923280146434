import React, { useId } from "react";

import classnames from "classnames/bind";

import styles from "./SlideCheckbox.module.scss";

const cx= classnames.bind(styles);

function SlideCheckbox({
    checked, //
    disabled,
    onChange,
    renderLabel,
    ...attributes
}: Props) {
    const inputId = useId();

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!disabled) {
            onChange(event.target.checked, event);
        }
    }

    return (
        <div className={cx("slide-checkbox")}>
            <label
                htmlFor={inputId}
                className={cx({
                    disabled,
                })}
            >
                <input
                    id={inputId}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                    {...attributes}
                />
                { renderLabel && <div className={cx("label-text")}>{renderLabel(checked, disabled || false)}</div>}
                <div className={cx("checkbox-wrapper")}>
                    <div className={cx("circle")} />
                </div>
            </label>
        </div>
    );
}

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    checked: boolean;
    onChange: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
    renderLabel?: (checked: boolean, disabled: boolean) => React.ReactNode;
}

export default SlideCheckbox;
