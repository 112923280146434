import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import classNames from "classnames/bind";

import { NewCallTypeButton } from "..";

import { IconClose } from "../../assets/icons";
import { IState } from "../../store";
import { setPopupDeleteCustomCallTypeOpen, setType, useGetCallTypesQuery } from "../../store/services";
import { dataCallTypes, EKeyCallType, ICallType } from "../../constants";

import styles from "./CallTypes.module.scss";

const cx = classNames.bind(styles);

const CallTypes = () => {
  const activeType = useSelector(
    (state: IState) => state.activeTypeReducer.type,
  );

  const isRefetch = useSelector((state: IState) => state.refetchReducer.value);
  const userEmail = useSelector((state: IState) => state.userReducer.email);
  const dispatch = useDispatch();

 const { data: callTypes, refetch } = useGetCallTypesQuery(userEmail);

  useEffect(() => {
    refetch();
  }, [isRefetch]);

  const handleActive = (type: ICallType) => {
    dispatch(setType(type));
  };

  const handleDelete = (el: ICallType) => {
    if (el[EKeyCallType.ID]) {
      dispatch(setPopupDeleteCustomCallTypeOpen(el));
    }
  };

  return (
    <div className={cx("call-types")}>
      <div className={cx("lists-wrapper")} id="call-types-list-wrapper">
        <div>
          <h2>Call Types</h2>
          <div className={cx("call-types-wrapper")}>
            {dataCallTypes.map((el) => {
              const active = el[EKeyCallType.NAME] === activeType?.[EKeyCallType.NAME];

              return (
                <div
                  data-call-type={el.Name}
                  key={el[EKeyCallType.NAME]}
                  className={cx("call-type-item",
                    {
                      active,
                      noactive: !active,
                    },
                  )}
                  onClick={() => handleActive(el)}
                >
                  {el.Name}
                </div>
              );
            })}
          </div>
        </div>
        {callTypes && callTypes.length > 0 && (
          <div>
            <h2>Custom Call Types</h2>
            <div className={cx("call-types-wrapper", "custom-call-types-wrapper")}>
              {callTypes.map((el) => {
                const active = el[EKeyCallType.NAME] === activeType?.[EKeyCallType.NAME];

                return (
                  <div
                    data-call-type={el.Name}
                    key={el[EKeyCallType.NAME]}
                    className={cx("call-type-item",
                      {
                        active,
                        noactive: !active,
                      },
                    )}
                    onClick={() => handleActive(el)}
                  >
                    {el.Name}

                    <button
                      type="button"
                      className={cx("delete-button")}
                      onClick={(event) => {
                        event.stopPropagation();

                        handleDelete(el);
                      }}
                    >
                      <IconClose size="20px" />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className={cx("new-call-type")}>

      <NewCallTypeButton />
      </div>
    </div>
  );
};

export default CallTypes;
