import React from "react";

import styles from "./Container.module.scss";
import classNames from "classnames/bind";

interface IContainerProps {
  children: React.ReactNode;
  autoHeight?: boolean;
}

const cx = classNames.bind(styles);

const Container = ({ children, autoHeight = false }: IContainerProps) => {
  return <div className={cx(styles.container, {[styles.autoHeight]: autoHeight })}>{children}</div>;
};

export default Container;
