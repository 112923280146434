import classNames from "classnames/bind";

import styles from "./ResetChangesButton.module.scss";

interface IResetChangesButtonPeops {
  onReset: () => void;
}

const cx = classNames.bind(styles);

const ResetChangesButton = ({ onReset }: IResetChangesButtonPeops) => {
  return (
    <button
      className={cx("button")}
      onClick={onReset}
    >
      <span>Discard Changes</span>
    </button>
  );
};

export default ResetChangesButton;
