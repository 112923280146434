import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { EEndpoints, ICallType } from "../../constants";
import { handleURLUser, handleURLWithFilters } from "../../helpers";
import { EDataKeys, TData, TDataManager, TSubmittedData } from "../../types/TData";
import { ENoteKeys, EProjectNoteKeys, TProjectNote, TNote } from "../../types/TNote";
import { IFiltersState } from "./filters";
import { setType } from "./activeType";
import config from "../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";

export type TProjectNotePayload = {
  "Related Site": string | number,
  Description?: string,
  Type: string | undefined,
  "Submission Id": string,
  "Submission Type": string | undefined,
  "Submitted By": string,
}

type TUpdateProjectNotePayload = {
  Id: string;
} & Partial<Omit<TProjectNotePayload, 'Id'>>;


export type ProjectTask = {
  description: string;
  assignedToRole?: string;
  assignee?: string;
  projectPhase?: string;
  department?: string;
  personalTask?: boolean;
  startDate: Date;
  endDate: Date;
  projectId: string | number;
}
export const projectApi = createApi({
  reducerPath: "projectApi",
  baseQuery: fetchBaseQuery({ baseUrl: EEndpoints.API_ENDPOINT }),
  tagTypes: ["CallType", "Project"],
  endpoints: (builder) => ({
    getProjects: builder.query<TData[], { activeType: ICallType | null, filter: IFiltersState }>({
      query: ({ activeType, filter }: { activeType: ICallType, filter: IFiltersState }) => {
        return handleURLWithFilters(activeType, filter);
      },
      providesTags: ["Project"]
    }),
    updateProject: builder.mutation<{ status: 200 }, ({ Id: string } & Partial<TData>)[]>({
      query: (body) => ({
        url: "/Project/update.json",
        method: "POST",
        body
      }),
      invalidatesTags: ["Project"]
    }),
    getManager: builder.query<TDataManager[], string>({
      query: () =>
        "/User/select.json?column=Abbreviation&column=First%20Name&column=Last%20Name&column=Number%20of%20Projects&column=Full%20Name&column=E-mail&column=User&filter=%5BIT%20Project%20Director%20Positions%5D%3E0",
    }),
    getHistory: builder.query<TNote[], string>({
      query: () => {
        const searchParams = new URLSearchParams();
        searchParams.append("column", ENoteKeys.TYPE);
        searchParams.append("column", ENoteKeys.SUBMITTED_BY);
        searchParams.append("column", ENoteKeys.ID);
        searchParams.append("column", ENoteKeys.DATE_CREATED);
        searchParams.append("column", ENoteKeys.NOTES_IN_SUBMISSION);
        searchParams.append("column", ENoteKeys.SUBMISSION_ID);
        searchParams.append("filter", `[First Item in Submission]=[Id]`);
        searchParams.append("top", "20");
        searchParams.append("sort", "Id//DESC");
        
        return `/${config.PROJECT_NOTES_TABLE_NAME}/select.json?${searchParams.toString()}`;
      },
    }),
    getUser: builder.query<any, string>({
      query: () => handleURLUser(),
    }),
    getCallTypes: builder.query<ICallType[], string>({
      query: (userEmail) => {
        const searchParams = new URLSearchParams();

        searchParams.append("filter", `[User]="${userEmail}" or [Access]="Public"`);
        return `/Project%20Note%20Type/select.json?${searchParams.toString()}`;
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const activeTypeId = (getState() as any)?.activeTypeReducer?.type?.Id;
          const activeType = data.find(item => item.Id === activeTypeId);
          if (activeType) {
            dispatch(setType(activeType));
          }
        } catch (e) { void e }
      },
      providesTags: ["CallType"]
    }),
     getSubmittedData: builder.query<TSubmittedData[], { submissionId: string }>({
      query: (data) => `/${config.PROJECT_NOTES_TABLE_NAME}/select.json?column=Id&filter=[Submission Id]="${data.submissionId}"&sort=Submission%20Id`,
    }),

    getSite: builder.query<{ [EDataKeys.ROW_ID]: number, ["Related Site"]: string }[], string>({
      query: (marshaCode) => `/Project/select.json?column=Related%20Site&filter=%5BMARSHA%5D%3D%22${marshaCode}%22`,
    }),
    getProjectNotes: builder.query<TProjectNote[], { activeType: string, relatedSite: string }>({
      query: ({ activeType, relatedSite }) => {
        const searchParams = new URLSearchParams();
        searchParams.append("filter", `[Type]="${activeType}" and [Related Site]="${relatedSite}" and not IsNull([Description])`);
        searchParams.append("column", EProjectNoteKeys.DATE_CREATED);
        searchParams.append("column", EProjectNoteKeys.DESCRIPTION);
        searchParams.append("column", EProjectNoteKeys.SUBMITTED_BY);
        searchParams.append("column", EProjectNoteKeys.NOMAD_IMPORT);
        searchParams.append("sort", `${EProjectNoteKeys.DATE_CREATED}//DESC`);

        return `/${config.PROJECT_NOTES_TABLE_NAME}/select.json?${searchParams.toString()}`;
      },
    }),
    getProjectNotesBySubmissionId: builder.query<TProjectNote[], { submissionId: string, page?: number }>({
      query: ({ submissionId, page = 1 }) => {
        const skip = page * 500 - 500;

        const searchParams = new URLSearchParams();
        searchParams.append("filter", `[Submission Id]="${submissionId}"`);
        searchParams.append("column", ENoteKeys.ID);
        searchParams.append("column", ENoteKeys.SUBMISSION_ID);
        searchParams.append("skip", skip.toString());

        return `/${config.PROJECT_NOTES_TABLE_NAME}/select.json?${searchParams.toString()}`;
      },
    }),
    getUsers: builder.query<{ "Full Name": string, "E-mail": string }[], undefined>({
      query: () => {
        const urlParams = new URLSearchParams();
        urlParams.append("filter",
          "[Role]='IT Opening Leadership' or [Role]='IT Project Director' or [Role]='PMO' or [Role]='IT Tech Team' or [Role]='Senior Manager Projects'");

        urlParams.append("column", "Full Name");
        urlParams.append("column", "E-mail");

        return `/User/select.json?${urlParams.toString()}`
      }
    }),
    createProjectNote: builder.mutation<{ status: 200 }, TProjectNotePayload[]>({
      query: (body) => ({
        url: `/${config.PROJECT_NOTES_TABLE_NAME}/create.json`,
        method: "POST",
        body,
      }),
    }),
    updateProjectNotes: builder.mutation<{ status: 200 }, TUpdateProjectNotePayload[]>({
      query: (body) => ({
        url: `/${config.PROJECT_NOTES_TABLE_NAME}/update.json`,
        method: "POST",
        body,
      }),
    }),
    updateCustomCallType: builder.mutation<{ status: number, id: number }[], { id: string | number, payload: Record<string, unknown> }>({
      query: (params) => ({
        url: "/Project%20Note%20Type/update.json",
        method: "POST",
        body: { id: params.id, ...params.payload }
      }),
      invalidatesTags: ["CallType"]
    }),
    deleteCustomCallType: builder.query<{ status: number, id: number }[], { id: string }>({
      query: (params) => ({
        url: "/Project%20Note%20Type/delete.json",
        method: "GET",
        params: {
          id: params.id,
          purge: "true",
        },
      }),
    }),
    createProjectTask: builder.mutation<{ status: number, id: number }[], ({projectId: string | number} & ProjectTask)[]>({
      query: (body) => ({
        url: "/Project%20Task/create.json",
        method: "POST",
        body: body.map((item: ProjectTask) => ({
          "Name / Description": item.description,
          "Assigned to Role": item.assignedToRole,
          "Assigned To": item.assignee,
          "Project Phase": item.projectPhase,
          "Department": item.department,
          "Personal Task": item.personalTask,
          "Start Date Adjustment": item.startDate.toISOString(),
          "Finish Date Adjustment": item.endDate.toISOString(),
          "Related Project": item.projectId
        }))
      })
    })
  }),
});


export const fetchAllProjectNotes = createAsyncThunk(
  "projects/fetchAll",
  async ({ page = 1, submissionId}: {page?: number, submissionId: string}, { dispatch }) => {
    let hasMore = true;


    let currentPage = page;

    let resultSet: TProjectNote[] = [];
    while (hasMore) {
      const res = await dispatch(projectApi.endpoints.getProjectNotesBySubmissionId.initiate({ submissionId, page: currentPage }));

      if(res.data) resultSet = resultSet.concat(res.data);
      if (res.data?.length === 500) {
        currentPage++;
      } else {
        hasMore = false;
      }
    }

    return resultSet;
  },
);


export const {
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  useLazyGetProjectNotesBySubmissionIdQuery,
  useGetManagerQuery,
  useGetHistoryQuery,
  useGetUserQuery,
  useGetCallTypesQuery,
  useGetSiteQuery,
  useGetProjectNotesQuery,
  useLazyDeleteCustomCallTypeQuery: useDeleteCustomCallTypeLazy,
  useUpdateCustomCallTypeMutation,
  useUpdateProjectMutation,
  useGetUsersQuery,
  useCreateProjectTaskMutation,
  useUpdateProjectNotesMutation,
  useLazyGetHistoryQuery,
} = projectApi;
