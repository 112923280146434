import classNames from "classnames/bind";

import { IconViewWeek } from "../../../assets/icons";

import styles from "./ColumnSelectorButton.module.scss";
import { useState } from "react";
import PopupColumnSelector from "../../popups/PopupColumnSelector/PopupColumnSelector";

const cx = classNames.bind(styles);

const ColumnSelectorButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handlePopupColumnSelector = () => setIsOpen(!isOpen);

  return (
    <div className={cx("column-selector-button")}>
      <div onClick={handlePopupColumnSelector}>
        <IconViewWeek size="24px" />
      </div>
      {isOpen && <PopupColumnSelector onClose={handlePopupColumnSelector} />}
    </div>
  );
};

export default ColumnSelectorButton;
