import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";

import {
  CallNoteHistory,
  ColumnSelectorButton,
  Search,
} from "../";

import Select from "../SelectNew";

import { IconArrowClockwise, IconDone } from "../../assets/icons";

import { useGetManagerQuery } from "../../store/services";
import {
  EKeyCallType,
  ICallType,
  contractTypeOptions,
  oracleVendors,
  uatStatusOptions,
  uatVendors,
  status
} from "../../constants";

import styles from "./CallFilters.module.scss";

import {
  EDataManagerKeys,
  TDataManager,
} from "../../types/TData";
import { EFilterTypes } from "../../types/TFilter";
import { IState } from "../../store";
import useFilters from "./useFilters";
import createSelectOptions from "../../helpers/createSelectOptions";

interface ICallFiltersProps {
  activeType: ICallType;
}

const cx = classNames.bind(styles);

const CallFilters = ({
  activeType,
}: ICallFiltersProps) => {
  const { filters, customCallType, onChange, onQueryChange } = useFilters();

  const [fromNumber, setFromNumber] = useState<number | null>(0);
  const [toNumber, setToNumber] = useState<number | null>(0);

  const newNotes = useSelector((state: IState) => state.newNotesReducer.newNotes
    .filter((el) => el.activeTypeName === activeType?.[EKeyCallType.NAME]));

  const { data } = useGetManagerQuery("");

  const arrManagers = useMemo(() => {
    if (data) return data.filter((el) => el[EDataManagerKeys.ABREVIATION]);
  }, [data]);

  useEffect(() => {
    if (filters[EFilterTypes.DAYS]) {
      const [from, to] = filters[EFilterTypes.DAYS];
      setFromNumber(from);
      setToNumber(to);
    }
  }, [filters[EFilterTypes.DAYS]])



  const [defaultFrom, defaultTo] = JSON.parse(activeType.Days) as number[];

  return (
    <div className={cx("topbar", "height-with-filters")} id="topbar">
      <div className={cx("topbar-box")}>
        <div className={cx("quick-filters")}>
          <div className={cx("daystoopening")}>
            <h4>Days to Opening</h4>
            <div className={cx("daystoopening-wrapper")}>
              <div className={cx("days-wrapper")}>
                <input
                  type="number"
                  min="-300"
                  max="300"
                  placeholder="From"
                  id="relative-date-from"
                  onChange={(e) => setFromNumber(e.target.value ? +e.target.value : null)}
                  value={fromNumber !== null ? fromNumber : ""}
                  disabled={newNotes.length > 0}
                />
                <span>-</span>
                <input
                  type="number"
                  min="-300"
                  max="300"
                  placeholder="To"
                  id="relative-date-to"
                  onChange={(e) => setToNumber(e.target.value ? +e.target.value : null)}
                  value={toNumber !== null ? toNumber : ""}
                  disabled={newNotes.length > 0}
                />
              </div>
              <div className={cx("buttons-wrapper")}>
                <button
                  className={cx("apply-days")}
                  onClick={() => onChange(EFilterTypes.DAYS, [
                    fromNumber !== null ? fromNumber : null,
                    toNumber !== null ? toNumber : null
                  ])}
                  disabled={newNotes.length > 0}
                >
                  <IconDone />
                </button>
                <button
                  className={cx("reset-days")}
                  onClick={() => onChange(EFilterTypes.DAYS, [defaultFrom, defaultTo])}
                  disabled={newNotes.length > 0}
                >
                  <IconArrowClockwise />
                </button>
              </div>
            </div>
          </div>
          <div className={cx("select-box")}>
            <Select
              options={createSelectOptions(contractTypeOptions)}
              onChange={(value) => onChange(EFilterTypes.CONTRACT_TYPE, value || "")}
              value={filters[EFilterTypes.CONTRACT_TYPE]}
              isClearable
              placeholder="Contract Type"
            // isDisabled={newNotes.length > 0}
            />
          </div>
          <div className={cx("select-box")}>
            <Select
              options={createSelectOptions([
                "None",
                ...arrManagers?.map((el) => el[EDataManagerKeys.ABREVIATION]) || [],
              ])}
              onChange={(value) => onChange(EFilterTypes.PROJECT_DIRECTORS, value || "")}
              value={filters[EFilterTypes.PROJECT_DIRECTORS]}
              isClearable
              placeholder="Project Directors"
            // isDisabled={newNotes.length > 0}
            />
          </div>
          <div className={cx("select-box-multi")}>
            <Select
              options={createSelectOptions(status)}
              onChange={(value) => onChange(EFilterTypes.STATUS, value || [])}
              value={filters[EFilterTypes.STATUS]}
              isClearable
              isMulti
              placeholder="Status"
              closeMenuOnSelect={false}
            // isDisabled={newNotes.length > 0}
            />
          </div>
          {(activeType?.[EKeyCallType.ORACLE_VENDOR] || customCallType) && (
            <div className={cx("select-box")}>
              <Select
                options={createSelectOptions(oracleVendors)}
                onChange={(value) => onChange(EFilterTypes.ORACLE_VENDORS, value || "")}
                placeholder="Oracle Vendors"
                value={filters[EFilterTypes.ORACLE_VENDORS]}
                isClearable
              // isDisabled={newNotes.length > 0}
              />
            </div>
          )}
          <Search
            onChange={onQueryChange}
            value={filters[EFilterTypes.SEARCH]}
          />
        </div>
        <div className={cx("buttons-wrapper")}>
          <ColumnSelectorButton />
          <CallNoteHistory />
        </div>
      </div>
      <div className={cx("uat-wrapper")}>
        {(activeType?.[EKeyCallType.UAT_VENDOR] || customCallType) && (
          <>
            <div className={cx("select-box")}>
              <Select
                options={createSelectOptions(uatVendors)}
                onChange={(value) => onChange(EFilterTypes.UAT_VENDORS, value || "")}
                placeholder="UAT Vendors"
                value={filters[EFilterTypes.UAT_VENDORS]}
                isClearable
              // isDisabled={newNotes.length > 0}
              />
            </div>
            <div className={cx("select-box")}>
              <Select
                options={createSelectOptions(uatStatusOptions)}
                onChange={(value) => onChange(EFilterTypes.UAT_STATUS, value || "")}
                placeholder="UAT Status"
                value={filters[EFilterTypes.UAT_STATUS]}
                isClearable
              // isDisabled={newNotes.length > 0}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CallFilters;
