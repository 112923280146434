import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ICallTypeState {
  // value: string[];
  callTypeColumns: any;
}

const initialState: ICallTypeState = {
  // value: [],
  callTypeColumns: [],
};

export const callTypeSlice = createSlice({
  name: "callType",
  initialState,
  reducers: {
    // setColType: (state, action: PayloadAction<string>) => {
    //   state.value = JSON.parse(action.payload);
    // },
    setCallTypeColumns: (state, action: PayloadAction<string>) => {
      state.callTypeColumns = JSON.parse(action.payload);
    },
  },
});

export const { setCallTypeColumns } = callTypeSlice.actions;

export default callTypeSlice.reducer;
