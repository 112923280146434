import { useEffect, useState } from "react";
import classNames from "classnames/bind";

import { IconClose } from "../../assets/icons";
import CallNoteHistoryTable from "../CallNoteHistoryTable";

import { useGetHistoryQuery } from "../../store/services";

import styles from "./SidebarHistory.module.scss";

import { TNote } from "../../types/TNote";

interface ISidebarHistoryProps {
  isOpen: boolean;
  onClose: () => void;
}

const cx = classNames.bind(styles);

const SidebarHistory = ({ isOpen, onClose }: ISidebarHistoryProps) => {
  const [notes, setNotes] = useState<TNote[]>([]);

  const { data, refetch } = useGetHistoryQuery("");

  useEffect(() => {
    if (data) setNotes(data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [isOpen, refetch]);

  const handleClose = () => {
    onClose();
  };

  return (
    <div className={cx("sidebar-wrapper", isOpen && "open")}>
      <div className={cx("sidebar-inner", isOpen && "open")}>
        <button className={cx("close")} onClick={handleClose}>
          <IconClose />
        </button>
        {notes.length > 0 ? (
          <div className={cx("content-wrapper")}>
            <div className={cx("title")}>
              <h3>Call Note History</h3>
            </div>
            <div>
              <CallNoteHistoryTable data={notes} />
            </div>
          </div>
        ) : (
          <div>Loading ...</div>
        )}
      </div>
    </div>
  );
};

export default SidebarHistory;
