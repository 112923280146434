import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IUserState {
  email: string;
}

const initialState: IUserState = {
    email: "bschulz@apexdigital.online"
};

export const userSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
});

export const { setUserEmail } =
  userSlice.actions;

export default userSlice.reducer;
