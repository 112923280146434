import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../store";
import { IFiltersState, setFilter, useUpdateCustomCallTypeMutation } from "../../store/services";
import { debounce } from "../../helpers";
import { EFilterTypes } from "../../types/TFilter";
import { EKeyCallType, dataCallTypes } from "../../constants";

function useFilters() {
    const filters = useSelector((state: IState) => state.filtersReducer);

    const activeType = useSelector((state: IState) => state.activeTypeReducer.type);

    const [updateCustomCallType] = useUpdateCustomCallTypeMutation();


    const customCallType = useMemo(() => !dataCallTypes.find(item => item.Id === activeType?.Id), [activeType]);

    const dispatch = useDispatch();

    const handleChange = useCallback((name: keyof IFiltersState, value: string | string[] | [number | null, number | null]) => {
        if (customCallType) {
            let valueToSave = value;
            let currentName: string = name;

            if(currentName === EFilterTypes.PROJECT_DIRECTORS) {
                currentName = EKeyCallType.PROJECT_DIRECTORS;
            }
            if(currentName === EFilterTypes.ORACLE_VENDORS) {
                currentName = EKeyCallType.ORACLE_VENDOR
            }
            if(currentName === EFilterTypes.UAT_VENDORS) {
                currentName = EKeyCallType.UAT_VENDOR
            }
            if(currentName === EFilterTypes.STATUS || currentName === EFilterTypes.DAYS) {
                valueToSave = JSON.stringify(value);
            }
            updateCustomCallType({ id: activeType?.Id || "", payload: { [currentName]: valueToSave } })
        }
        dispatch(setFilter({
            name,
            value,
        }));


    }, [dispatch, customCallType, activeType, updateCustomCallType]);

    const debouncedHandleChange: (value: string) => void = useMemo(() => {
        let key = EFilterTypes.SEARCH;
        return debounce((value: string) => handleChange(key, value), 400);
    }, [handleChange]);

    return {
        filters,
        customCallType,
        onChange: handleChange,
        onQueryChange: debouncedHandleChange,
    };
}

export default useFilters;