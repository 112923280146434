import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICallType } from "../../constants";

export interface IPopupState {
  popupChangeColumns: {
    isOpen: boolean;
  };
  popupNewCallType: {
    isOpen: boolean;
  };
  popupDownloadNewNotes: {
    isOpen: boolean;
  };
  popupNoteHistory: {
    isOpen: boolean;
  };
  popupDeleteCustomCallType: {
    isOpen: boolean,
    callType: ICallType | null
  },
  popupDiscardNotes: {
    isOpen: boolean,
  },
  popupAddTask: {
    isOpen: boolean,
    projectId: string | number
  }
}

const initialState: IPopupState = {
  popupChangeColumns: {
    isOpen: false,
  },
  popupNewCallType: {
    isOpen: false,
  },
  popupDownloadNewNotes: {
    isOpen: false,
  },
  popupNoteHistory: {
    isOpen: false,
  },
  popupDeleteCustomCallType: {
    isOpen: false,
    callType: null,
  },
  popupDiscardNotes: {
    isOpen: false,
  },
  popupAddTask: {
    isOpen: false,
    projectId: 0
  }
};

export const popupSlice = createSlice({
  name: "popups",
  initialState,
  reducers: {
    setPopupChangeColumns: (state, action: PayloadAction<boolean>) => {
      state.popupChangeColumns.isOpen = action.payload;
    },
    setPopupNewCallType: (state, action: PayloadAction<boolean>) => {
      state.popupNewCallType.isOpen = action.payload;
    },
    setPopupDownloadNewNotes: (state, action: PayloadAction<boolean>) => {
      state.popupDownloadNewNotes.isOpen = action.payload;
    },
    setPopupNoteHistory: (state, action: PayloadAction<boolean>) => {
      state.popupNoteHistory.isOpen = action.payload;
    },
    setPopupDeleteCustomCallTypeOpen: (state, action: PayloadAction<ICallType>) => {
      state.popupDeleteCustomCallType.isOpen = true;
      state.popupDeleteCustomCallType.callType = action.payload;
    },
    setPopupDeleteCustomCallTypeClose: (state) => {
      state.popupDeleteCustomCallType.isOpen = false;
      state.popupDeleteCustomCallType.callType = null;
    },
    setPopupDiscardNotesOpen: (state) => {
      state.popupDiscardNotes.isOpen = true;
    },
    setPopupDiscardNotesClose: (state) => {
      state.popupDiscardNotes.isOpen = false;
    },
    setPopupAddTaskOpen: (state, action: PayloadAction<string | number>) => {
      state.popupAddTask.isOpen = true;
      state.popupAddTask.projectId = action.payload;
    },
     setPopupAddTaskClose: (state) => {
      state.popupAddTask.isOpen = false;
    }
  },
});

export const {
  setPopupChangeColumns,
  setPopupNewCallType,
  setPopupDownloadNewNotes,
  setPopupNoteHistory,
  setPopupDeleteCustomCallTypeOpen,
  setPopupDeleteCustomCallTypeClose,
  setPopupDiscardNotesOpen,
  setPopupDiscardNotesClose,
  setPopupAddTaskClose,
  setPopupAddTaskOpen
} = popupSlice.actions;

export default popupSlice.reducer;
