import { useState, useEffect } from "react";
import classNames from "classnames/bind";

import { IconClose } from "../../assets/icons";

import styles from "./Select.module.scss";

const cx = classNames.bind(styles);

interface ISelectProps {
  startOption?: string;
  options: string[];
  onSelect: (value: string) => void;
  onReset?: () => void;
  disabled?: boolean;
  restart?: boolean;
}

const Select = ({
  startOption,
  options,
  onSelect,
  onReset,
  disabled,
  restart,
}: ISelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [option, setOption] = useState(startOption || options[0]);

  useEffect(() => {
    setOption(startOption || options[0]);
  }, [restart]);

  const controlStartValue = () => startOption || options[0];

  const toggleIsOpen = () => {
    if (!disabled) setIsOpen(!isOpen);
  };

  const handleOption = (value: string) => {
    setOption(value);
    onSelect(value);
  };

  const handleReset = () => {
    setOption(startOption || options[0]);
    if (onReset) onReset();
  };

  return (
    <div className={cx("box")}>
      {onReset && option !== controlStartValue() && (
        <button className={cx("reset")} onClick={handleReset}>
          <IconClose size="16px" />
        </button>
      )}
      <div
        className={cx("nice-select", isOpen && "open", disabled && "disabled")}
        onClick={toggleIsOpen}
      >
        <span>{option}</span>
        <ul className={cx("list", isOpen && "open")}>
          {options.map((el) => (
            <li
              key={el}
              className={cx("option")}
              onClick={() => handleOption(el)}
            >
              {el}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Select;
