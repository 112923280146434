import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserEmail } from "../../store/services/user";

function useReceiveUser() {
    const dispatch = useDispatch();
    useEffect(() => {
        const handleUser = (ev: MessageEvent<{ type: string, value: string }>) => {
            if (ev.data?.type === "userData" && ev.data?.value) {
                dispatch(setUserEmail(ev.data.value))
            }
        }
        window.addEventListener("message", handleUser)

        return () => window.removeEventListener("message", handleUser);
    }, [dispatch])

    useLayoutEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.user) {
            dispatch(setUserEmail(params.user));
        }
    }, [dispatch]);


}

export default useReceiveUser;