import { useDispatch } from "react-redux";
import classNames from "classnames/bind";

import { setPopupNewCallType } from "../../../store/services";

import styles from "./NewCallTypeButton.module.scss";

const cx = classNames.bind(styles);

const NewCallTypeButton = () => {
  const dispatch = useDispatch();

  const handleOpenPopup = () => dispatch(setPopupNewCallType(true))

  return (
    <button
      className={cx("button")}
      onClick={handleOpenPopup}
    >
      <span>Add New Call Type</span>
    </button>
  );
};

export default NewCallTypeButton;
