import React from "react";
import Select from "../../SelectNew/Select";
import classNames from "classnames/bind";
import { uatStatusOptions } from "../../../constants";
import styles from "./UATStatusSelect.module.scss";

const cx = classNames.bind(styles);

function UATStatusSelect({ value, onChange }: Props) {
    return (
        <div className={cx("wrapper")}>
            <label htmlFor="uat-status">UAT Status</label>
            <Select
                placeholder="UAT Status"
                options={uatStatusOptions.map((item) => ({
                    value: item,
                    label: item
                }))}
                id="uat-status"
                onChange={(value) => {
                    onChange(value as string);
                }}
                value={value}
                isClearable
            />
        </div>
    )
}

type Props = {
    value: string,
    onChange: (newValue: string) => void
}

export default UATStatusSelect;