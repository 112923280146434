import { useSelector } from "react-redux";
import React from "react";
import classNames from "classnames/bind";

import { CallFilters, ProjectList } from "../";

import { IState } from "../../store";

import styles from "./ProjectWrapper.module.scss";

const cx = classNames.bind(styles);

const ProjectWrapper = ({ autoHeight = false}) => {
  const activeType = useSelector(
    (state: IState) => state.activeTypeReducer.type
  );

  return (
    <div className={cx("wrapper")}>
      <h2 className={cx("title")}>{activeType?.Name}</h2>
      {activeType && (
        <>
          <CallFilters
            activeType={activeType}
          />
          <ProjectList
            activeType={activeType}
            autoHeight={autoHeight}
          />
        </>
      )}
    </div>
  );
};

export default ProjectWrapper;
