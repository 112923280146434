const IconChevronDown = ({ fillColor = "#1D2433", size = "24px" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="CaretDown">
        <path
          id="Vector"
          d="M3.75 13.75L10 7.5L16.25 13.75"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IconChevronDown;
