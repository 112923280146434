import { ICellRendererParams } from "@ag-grid-community/core";

import { EKeyCallType, ICallType, dataCallTypes, standardColumns } from "../constants";

interface IHeader {
  headerName?: string;
  field?: string;
  editable?: boolean;
}

export const createHeaders = (
  activeType: ICallType,
  colFiltered: number[] = [],
  isWithoutLastNotes?: boolean
) => {
  let columns: number[] = [];
  let headers: IHeader[] = [];
  const customCallType = !dataCallTypes.find(item => item.Id === activeType.Id);
  if (activeType)
    columns =
      !customCallType && colFiltered.length ?
        standardColumns
          .map(col => col.columnId)
          .filter((col: number) => !colFiltered.includes(col))
        : JSON.parse(activeType[EKeyCallType.CUSTOM_FILTERS]);

  if (columns)
    headers = columns
      .map((col) => {
        return standardColumns.find((el) => el.columnId === col);
      })
      .filter(
        (elem) =>
          elem?.columnId !== 2 &&
          elem?.columnId !== 3 &&
          !elem?.doNotPullOutOfTheCOMPASS &&
          !(elem?.name === "Last Notes" && isWithoutLastNotes)
      )
      .map((header) => {
        if (header?.name === "IT OD" || header?.name === "OASIS OD") {
          return {
            headerName: header?.name,
            field: header?.id,
            filter: "agDateColumnFilter",
            filterParams: {
              comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
                if (!cellValue) {
                  return 1;
                }

                const cellDate = new Date(cellValue);

                // Now that both parameters are Date objects, we can compare
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                } else if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
                return 0;
              }
            }
          }
        }
        if (header?.isDataJSX) {
          if (header?.name === "Last Notes") {
            return {
              headerName: header?.name,
              field: activeType[EKeyCallType.NOTE],
              cellRendererSelector: (params: ICellRendererParams) => {
                const component = {
                  component: () => (
                    <div dangerouslySetInnerHTML={{ __html: params.value }} />
                  ),
                };
                return component;
              },
              minWidth: 200,
            };
          }
          return {
            headerName: header?.name,
            field: header?.id,

            cellRendererSelector: (params: ICellRendererParams) => {
              const component = {
                component: () => <div>{params.value}</div>,
              };
              return component;
            },
          };
        }
        return { headerName: header?.name, field: header?.id };
      })
      .sort((a, b) => {
        if (a.headerName === "Last Notes") return 1;
        if (b.headerName === "Last Notes") return -1;
        return 0;
      })
      ;

  const callNotesColumnId = standardColumns.find(item => item.id === "Call Notes")?.columnId || -1;
  if (!colFiltered.includes(callNotesColumnId))
    headers.splice(headers.length - 1, 0, {
      headerName: "Call Notes",
      field: "Call Notes",
    });

  return headers;
};
