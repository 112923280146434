import { useEffect, useState } from "react";
import classNames from "classnames/bind";

import CallNoteHistoryTable from "../../CallNoteHistoryTable";

import { useGetHistoryQuery } from "../../../store/services";

import styles from "./PopupNoteHistory.module.scss";

import { TNote } from "../../../types/TNote";

const cx = classNames.bind(styles);

const PopupNoteHistory = () => {
  const [notes, setNotes] = useState<TNote[]>([]);

  const { data } = useGetHistoryQuery("");

  useEffect(() => {
    if (data) setNotes(data);
  }, [data]);

  return (
    <div className={cx("popup-note-history")}>
      <h3>Call Note History</h3>
      <CallNoteHistoryTable data={notes} />
    </div>
  );
};

export default PopupNoteHistory;
