import { KeyboardEvent, useEffect, useState } from "react";
import classNames from "classnames/bind";

import styles from "./Search.module.scss";
import { IconClose, IconSearch } from "../../assets/icons";

const cx = classNames.bind(styles);

interface ISearchProps {
  value: string;
  onChange: (value: string) => void;
  onReset?: () => void;
  disabled?: boolean;
}

const Search = ({ value, onChange, onReset, disabled }: ISearchProps) => {
  const [option, setOption] = useState(value || "");

  const handleInputState = (setState: (string: string) => void) => (e: any) => {
    if (e.target) {
      setState((e.target as HTMLInputElement).value);
      onChange((e.target as HTMLInputElement).value);
    }
  };

  const handleReset = () => {
    if(disabled) return;
    setOption("");
    onChange("");
    if (onReset) onReset();
  };

  useEffect(() => {
    setOption(value);
  }, [value])

  return (
    <div className={cx("box")}>
      {option.length > 0 && (
        <button className={cx("reset")} onClick={handleReset}>
          <IconClose size="16px" />
        </button>
      )}
      <div className={cx("icon")}>
        <IconSearch />
      </div>
      <input
        type="text"
        value={option}
        onChange={handleInputState(setOption)}
        placeholder="Search by keyword"
        disabled={disabled}
      />
    </div>
  );
};

export default Search;
