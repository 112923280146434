import { useState } from "react";
import classNames from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";

import { IconClose } from "../../../assets/icons";

import { IState } from "../../../store";
import { setPopupNewCallType, setRefetch } from "../../../store/services";
import {
  EEndpoints,
  EKeyCallType,
  ICallType,
  IColumn,
  accesses,
  standardColumns,
} from "../../../constants";

import styles from "./PopupNewCallType.module.scss";

const cx = classNames.bind(styles);

interface IPopupNewCallTypeProps {
  onClose: () => void;
}

const PopupNewCallType = ({ onClose }: IPopupNewCallTypeProps) => {
  const [newName, setNewName] = useState("");
  const [colsFiltered, setFiltered] = useState<number[]>([]);
  const [access, setAccess] = useState(accesses[0]);
  const [fromNumber, setFromNumber] = useState(0);
  const [toNumber, setToNumber] = useState(0);

  const userEmail = useSelector((state: IState) => state.userReducer.email);

  const dispatch = useDispatch();

  const isRefetch = useSelector((state: IState) => state.refetchReducer.value);

  const handleValue = (setState: (value: string) => void) => (e: any) => {
    if (e.target) setState((e.target as HTMLInputElement).value);
  };

  const handleSendData = async (newData: ICallType) => {
    try {
      const response = await fetch(
        EEndpoints.API_ENDPOINT + "/Project%20Note%20Type/create.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newData),
        }
      );
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async () => {
    const newCallTypeColumns = standardColumns
      .filter((item) => !item.hiddenColumn)
      .map((el) => el.columnId)
      .filter((elem) => !colsFiltered.includes(elem));
    dispatch(setPopupNewCallType(false));
    const newData: ICallType = {
      [EKeyCallType.NAME]: newName,
      [EKeyCallType.CUSTOM_FILTERS]: JSON.stringify(newCallTypeColumns),
      [EKeyCallType.ACCESS]: access,
      [EKeyCallType.NOTE]: `Last 3 ${newName} Call Notes`,
      [EKeyCallType.USER]: userEmail,
      [EKeyCallType.DAYS]: JSON.stringify([fromNumber, toNumber]),
    };
    const result = await handleSendData(newData);
    const refetch = await dispatch(setRefetch(!isRefetch));
    handleReset();
  };

  const handleNumber = (setState: (value: number) => void) => (e: any) => {
    if (e.target) setState(Number((e.target as HTMLInputElement).value));
  };

  const handleReset = () => {
    setNewName("");
    setAccess(access[0]);
    setFromNumber(0);
    setToNumber(0);
  };

  const handleFilter = (id: number) => {
    if (colsFiltered.includes(id)) {
      setFiltered(colsFiltered.filter((el) => el !== id));
    } else setFiltered([...colsFiltered, id]);
  };

  const columnsFiltered: IColumn[] = standardColumns.filter(
    (el: IColumn) => !el?.hiddenColumn
  );

  return (
    <div className={cx("column-selector")}>
      <button className={cx("close")} onClick={onClose}>
        <IconClose size="16px"/>
      </button>
      <h4>New Call Type</h4>
      <div className={cx("access-days-wrapper")}>
        <div className={cx("access-wrapper")}>
          {accesses.map((el) => (
            <div
              className={cx(access === el && "active")}
              key={el}
              onClick={() => setAccess(el)}
            >
              {el}
            </div>
          ))}
        </div>
        <div className={cx("daystoopening")}>
          <h4>Days to Opening</h4>
          <div className={cx("daystoopening-wrapper")}>
            <div className={cx("days-wrapper")}>
              <input
                type="number"
                min="1"
                max="999"
                placeholder="From"
                id="relative-date-from"
                onChange={handleNumber(setFromNumber)}
                value={fromNumber !== 0 ? fromNumber : ""}
              />
              <span>-</span>
              <input
                type="number"
                min="1"
                max="999"
                placeholder="To"
                id="relative-date-to"
                onChange={handleNumber(setToNumber)}
                value={toNumber !== 0 ? toNumber : ""}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={cx("input-wrapper")}>
        <input
          value={newName}
          placeholder="New name"
          onChange={handleValue(setNewName)}
        />
        <button className={cx("save-columns")} onClick={handleSave}>
          Save New Call Type
        </button>
      </div>

      <div className={cx("checkbox-wrapper")}>
        {columnsFiltered.map((el) => (
          <div
            className={cx(
              "column-checkbox",
              el && !colsFiltered.includes(el.columnId) && "checked"
            )}
            key={`column-selector-${el?.id}`}
            onClick={() => handleFilter(el?.columnId || 0)}
          >
            {el?.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopupNewCallType;
