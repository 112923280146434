import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";

import { IconArrowDown, IconChevronDown, IconClose } from "../../assets/icons";
import POSSelect from "../POSSelect/POSSelect";

import { IState } from "../../store";
import { setNewNote, setPopupAddTaskOpen, setSidebar, useGetProjectNotesQuery } from "../../store/services";

import styles from "./SidebarNotes.module.scss";

import { EDataKeys, TData } from "../../types/TData";
import { debounce, handleCreator, handleDate } from "../../helpers";
import { ECallTypeNames, EKeyCallType } from "../../constants";
import { EProjectNoteKeys, TProjectNote } from "../../types/TNote";
import UATFieldsForm from "../UATFieldsForm/UATFieldsForm";
import GhostButton from "../buttons/GhostButton";
import PMSFieldsForm from "../ PMSFieldsForm";

interface ISidebarNotesProps {
  data: TData | null;
  isOpen: boolean;
  onClose: () => void;
  allRows: TData[]
}

interface INewNote {
  "@row.id": number;
  Description: string;
  "NOMAD Import": boolean;
  "Record Owner": string;
  "Reminder Date": Date;
}

const cx = classNames.bind(styles);

const SidebarNotes = ({ data, onClose, isOpen, allRows }: ISidebarNotesProps) => {
  const [note, setNote] = useState("");
  const [isShowNewNotes, setIsShowNewNotes] = useState(true);
  const [isShowNotes, setIsShowNotes] = useState(true);
  const [isShowPOS, setIsShowPOS] = useState(true);
  const [isUATOpen, setUatOpen] = useState(true);
  const [isPMSOpen, setPMSOpen] = useState(true);

  const activeType = useSelector(
    (state: IState) => state.activeTypeReducer.type
  );

  const { data: notes } = useGetProjectNotesQuery({
    activeType: activeType?.[EKeyCallType.NAME] || "",
    relatedSite: data?.[EDataKeys.RELATED_SITE] || ""
  })

  const newNoteRef = useRef<HTMLTextAreaElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (newNoteRef.current && isOpen) newNoteRef.current.focus();
  }, [isOpen, data, newNoteRef.current]);

  const toggleShow = (state: boolean, setState: (value: boolean) => void) => {
    setState(!state);
  };

  const handleNewNote = useCallback((value: string) => {
    dispatch(setNewNote({
      newNote: {
        [EDataKeys.CALL_NOTES]: value,
        [EDataKeys.MARSHA]: data?.[EDataKeys.MARSHA],
        [EDataKeys.ROW_ID]: data?.[EDataKeys.ROW_ID] || 0,
        [EDataKeys.ID]: data?.[EDataKeys.ID] || "",
        [EDataKeys.RELATED_SITE]: data?.[EDataKeys.RELATED_SITE] || "",
        activeTypeName: activeType?.[EKeyCallType.NAME] || ""
      }
    }))
  }, [data, dispatch, activeType]);

  const debouncedHandleChange: (value: string) => void = useMemo(() => {
    return debounce((value) => handleNewNote(value), 400)
  }, [handleNewNote]);

  const handleChangeCurrentNote = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value)

    debouncedHandleChange(e.target.value);
  }

  useEffect(() => {
    if (data) {
      setNote(data?.[EDataKeys.CALL_NOTES] || "")
    }
  }, [data?.[EDataKeys.ROW_ID]])

  const handleClose = () => {
    onClose();
  };

  const handleDataSidebar = (comand: string) => {
    if (allRows && data) {
      const foundIndex = allRows.findIndex(row => row[EDataKeys.ROW_ID] === data?.[EDataKeys.ROW_ID]);


      if (comand === "up") {
        if (foundIndex > 0) dispatch(setSidebar({ activeId: allRows[foundIndex - 1]?.[EDataKeys.ROW_ID] }));
      }
      if (comand === "down") {
        if (foundIndex < allRows.length - 1)
          dispatch(setSidebar({ activeId: allRows[foundIndex + 1]?.[EDataKeys.ROW_ID] }));
      }
    }
  };

  const NOMADNote = (note: TProjectNote) => {
    return (<div className={cx("nomad-note")}>
      NOMAD: {note[EProjectNoteKeys.DESCRIPTION]}
    </div>
    )
  }

  const generalNote = (note: TProjectNote) => {
    const creator = handleCreator(note[EProjectNoteKeys.SUBMITTED_BY]);
    return (<>
      {handleDate(new Date(note[EProjectNoteKeys.DATE_CREATED]))} :{" "}
      {`${creator || ""}${creator ? " - " : ""}${note[EProjectNoteKeys.DESCRIPTION]}`}
    </>)
  }

  const handleNoUpdateClick = () => {
      dispatch(setNewNote({
      newNote: {
        [EDataKeys.CALL_NOTES]: "No Update",
        [EDataKeys.MARSHA]: data?.[EDataKeys.MARSHA],
        [EDataKeys.ROW_ID]: data?.[EDataKeys.ROW_ID] || 0,
        [EDataKeys.ID]: data?.[EDataKeys.ID] || "",
        [EDataKeys.RELATED_SITE]: data?.[EDataKeys.RELATED_SITE] || "",
        activeTypeName: activeType?.[EKeyCallType.NAME] || "",
      }
    }))

    handleDataSidebar("down");
  }

  return (
    <div className={cx("sidebar-wrapper", data && isOpen && "open")}>
      <div className={cx("sidebar-inner", data && isOpen && "open")}>
        <button className={cx("close")} onClick={handleClose}>
          <IconClose />
        </button>
        {data !== null ? (
          <div className={cx("content-wrapper")}>
            <div className={cx("header")}>
              <div className={cx("arrow-wrapper")}>
                <button
                  className={cx("arrow-up")}
                  onClick={() => handleDataSidebar("up")}
                >
                  <IconArrowDown />
                </button>
                <button
                  className={cx("arrow-down")}
                  onClick={() => handleDataSidebar("down")}
                >
                  <IconArrowDown />
                </button>
              </div>
              <div className={cx("title")}>
                <h3>{data[EDataKeys.PROJECT_NAME]}</h3>
              </div>
            </div>
            <div className={cx("action-wrapper")}>
              <button onClick={() => dispatch(setPopupAddTaskOpen(data.Id))} className={cx("add-task")}>
                Add Task
              </button>
              <GhostButton onClick={handleNoUpdateClick} title="No Update"/ >
            </div>
            <div
              className={cx(
                "new-notes-wrapper",
                isShowNewNotes && "collapse-open"
              )}
            >
              <div className={cx("new-notes-box")}>
                <h4>Call Notes</h4>
                <button
                  onClick={() => toggleShow(isShowNewNotes, setIsShowNewNotes)}
                  className={cx("button", isShowNewNotes && "open")}
                >
                  <IconChevronDown />
                </button>
              </div>
              {isOpen && (
                <textarea
                  ref={newNoteRef}
                  rows={5}
                  value={note}
                  onChange={(e) => handleChangeCurrentNote(e)}
                  placeholder="New Call Note"
                />
              )}
            </div>
            {activeType?.[EKeyCallType.NAME] === ECallTypeNames.POS &&
              <div className={cx("wrapper", isShowPOS && "collapse-open")}>
                <div className={cx("box")}>
                  <h4>Set POS Version</h4>
                  <button
                    onClick={() => toggleShow(isShowPOS, setIsShowPOS)}
                    className={cx("button", isShowPOS && "open")}
                  >
                    <IconChevronDown />
                  </button>
                </div>
                <POSSelect data={data} />
              </div>
            }
              {activeType?.[EKeyCallType.NAME] === ECallTypeNames.PMS &&
              <div className={cx("wrapper", isPMSOpen && "collapse-open")}>
                <div className={cx("box")}>
                  <h4>PMS/S&C Fields</h4>
                  <button
                    onClick={() => toggleShow(isPMSOpen, setPMSOpen)}
                    className={cx("button", isPMSOpen && "open")}
                  >
                    <IconChevronDown />
                  </button>
                </div>
                <PMSFieldsForm project={data} />
              </div>
            }
            {activeType?.[EKeyCallType.NAME] === ECallTypeNames.UAT &&
              <div className={cx("wrapper", isUATOpen && "collapse-open")}>
                <div className={cx("box")}>
                  <h4>UAT Fields</h4>
                  <button
                    onClick={() => toggleShow(isUATOpen, setUatOpen)}
                    className={cx("button", isUATOpen && "open")}
                  >
                    <IconChevronDown />
                  </button>
                </div>
                <UATFieldsForm project={data} />
              </div>
            }
            <div
              className={cx("notes-wrapper", isShowNotes && "collapse-open")}
            >
              <div className={cx("notes-box")}>
                <h4>Last Notes</h4>
                <button
                  onClick={() => toggleShow(isShowNotes, setIsShowNotes)}
                  className={cx("button", isShowNotes && "open")}
                >
                  <IconChevronDown />
                </button>
              </div>
              {notes && notes.length > 0 ? (
                <ul className={cx("last-notes-box")}>
                  {notes?.map((note) => (
                    <li key={note[EProjectNoteKeys.ROW_ID]}>
                      {note[EProjectNoteKeys.NOMAD_IMPORT] ? NOMADNote(note) : generalNote(note)}
                    </li>
                  ))}
                </ul>
              ) : (
                <div>No notes</div>
              )}
            </div>
          </div>
        ) : (
          <div>Loading ...</div>
        )}
      </div>
    </div>
  );
};

export default SidebarNotes;
