import { months } from "../constants";

export const handleDate = (date: any) => {
  if (!date) return "";
  const newDate = new Date(date)
  let day = newDate.getDate().toString();
  let month = months[(newDate.getMonth())];
  const year = newDate.getFullYear().toString();
  if (Number(day) < 10) day = `0${day}`;
  return [day, month, year].join(" ");
};
