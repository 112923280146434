import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Home,
  Loader,
  Popup,
  PopupDownloadNewNotes,
  PopupNewCallType,
  ProjectWrapper,
  SidebarHistory,
  SidebarMenu,
  SidebarNewNotes,
  SidebarNotes,
} from "../../components";

import { IState } from "../../store";
import {
  clearNewLinks,
  setPopupAddTaskClose,
  setPopupDeleteCustomCallTypeClose,
  setPopupDiscardNotesClose,
  setPopupDownloadNewNotes,
  setPopupNewCallType,
  setPopupNoteHistory,
  setSidebar,
} from "../../store/services";

import { EDataKeys } from "../../types/TData";
import useProjects from "../../components/ProjectList/useProjects";
import PopupDeleteCustomCallType from "../../components/popups/PopupDeleteCustomCallType";
import useReceiveUser from "./useReceiveUser";
import PopupDiscardNotes from "../popups/PopupDiscardNotes/PopupDiscardNotes";
import PopupAddTask from "../popups/PopupAddTask/PopupAddTask";
import { EKeyCallType } from "../../constants";
function Main() {
  const popups = useSelector((state: IState) => state.popupReducer);
  const activeType = useSelector(
    (state: IState) => state.activeTypeReducer.type,
  );

  useReceiveUser();

  const { activeRow, data } = useProjects({ activeType });

  const links = useSelector((state: IState) => state.newLinksReducer.value);

  const newNotes = useSelector((state: IState) => state.newNotesReducer.newNotes
    .filter((el) => el.activeTypeName === activeType?.[EKeyCallType.NAME] || !el.activeTypeName));

  const [isOpenSidebar, setIsOpenSidebar] = useState(
    true || activeType !== null,
  );
  const [isOpenMenu, setIsOpenMenu] = useState(true);

  const dispatch = useDispatch();

  const handleClosePopupNoteHistory = () => dispatch(setPopupNoteHistory(false));

  const handleCloseNewColType = () => dispatch(setPopupNewCallType(false));

  const handleCloseDownloadNewNotes = () => {
    dispatch(setPopupDownloadNewNotes(false));
    dispatch(clearNewLinks());
  };

  const handleCloseDeleteCustomCallType = () => dispatch(setPopupDeleteCustomCallTypeClose());

  const handleCloseSidebar = () => {
    if (isOpenSidebar) {
      setIsOpenSidebar(false);
      dispatch(setSidebar({ activeId: null }));
    } else setIsOpenSidebar(true);
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDataSidebar = (keyCode: number) => {
    if (data && activeRow) {
      const foundIndex = data.findIndex(row => row[EDataKeys.ROW_ID] === activeRow?.[EDataKeys.ROW_ID]);

      if (keyCode === 38) {
        if (foundIndex !== 0) dispatch(setSidebar({ activeId: data[foundIndex - 1]?.[EDataKeys.ROW_ID] }));
      }
      if (keyCode === 40) {
        if (foundIndex < data.length - 1)
          dispatch(setSidebar({ activeId: data[foundIndex + 1]?.[EDataKeys.ROW_ID] }));
      }
      if (keyCode === 37 && !isOpenSidebar) {
        setIsOpenSidebar(true);
      }
      if (keyCode === 39 && isOpenSidebar) {
        setIsOpenSidebar(false);
      }
    }
  };

  useEffect(() => {
    const handleUserKeyPress = (event: globalThis.KeyboardEvent) => {
      const { keyCode } = event;
      handleDataSidebar(keyCode);
    };
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleDataSidebar, activeRow]);

  useEffect(() => {
    if (activeRow && !isOpenSidebar) setIsOpenSidebar(true);
  }, [activeRow]);

  useLayoutEffect(() => {
    if(isOpenMenu) setIsOpenMenu(false);
  }, [activeType]);

  const params = new URLSearchParams(window.location.search);

  const autoHeight = params.get('autoHeight');

  return (
    <Container autoHeight={!!autoHeight}>
      <SidebarMenu isOpen={isOpenMenu} isBlocked={false} onClose={handleCloseMenu} />
      {activeType ?
        <ProjectWrapper autoHeight={!!autoHeight} />
        :
        <Home />}
      <Popup
        isOpen={popups.popupNewCallType.isOpen}
        onClose={handleCloseNewColType}
      >
        <PopupNewCallType onClose={handleCloseNewColType} />
      </Popup>
      <Popup isOpen={links.length > 0} onClose={handleCloseDownloadNewNotes}>
        <PopupDownloadNewNotes onClose={handleCloseDownloadNewNotes} />
      </Popup>
      <Popup
        isOpen={popups.popupDeleteCustomCallType.isOpen}
        onClose={handleCloseDeleteCustomCallType}
      >
        <PopupDeleteCustomCallType onClose={handleCloseDeleteCustomCallType} />
      </Popup>
      <Popup
        isOpen={popups.popupDiscardNotes.isOpen}
        onClose={() => dispatch(setPopupDiscardNotesClose())}
      >
        <PopupDiscardNotes onClose={() => dispatch(setPopupDiscardNotesClose())} />
      </Popup>
      <Popup
        isOpen={popups.popupAddTask.isOpen}
        onClose={() => dispatch(setPopupAddTaskClose())}
      >
        <PopupAddTask onClose={() => dispatch(setPopupAddTaskClose())} />
      </Popup>
      <SidebarNewNotes />
      <SidebarHistory
        isOpen={popups.popupNoteHistory.isOpen}
        onClose={handleClosePopupNoteHistory}
      />
      <SidebarNotes
        isOpen={isOpenSidebar}
        data={activeRow}
        onClose={handleCloseSidebar}
        allRows={data}
      />
      <Loader />
    </Container>
  );
}

export default Main;
