import { PayloadAction, createSlice, isFulfilled } from "@reduxjs/toolkit";
import { TSubmittedData } from "../../types/TData";
import { projectApi } from "./project";

export interface INewLinksState {
  value: TSubmittedData[];
}

const initialState: INewLinksState = {
  value: [],
};

export const newLinksSlice = createSlice({
  name: "newLinks",
  initialState,
  reducers: {
    setNewLinks: (state, action: PayloadAction<TSubmittedData>) => {
      state.value = [...state.value, action.payload];
    },
    clearNewLinks: (state) => {
      state.value = [];
    },
  },
  extraReducers(builder) {
      builder.addMatcher(projectApi.endpoints.getSubmittedData.matchFulfilled, (state, action) => {
        state.value = action.payload
      })
  },
});

export const { setNewLinks, clearNewLinks } = newLinksSlice.actions;

export default newLinksSlice.reducer;
