import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames/bind";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  IsFullWidthRowParams,
  RowClassParams,
  ICellRendererParams,
  RowHeightParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

import { IconDownload } from "../../assets/icons";

import { EEndpoints } from "../../constants";
import { handleCreator, handleDate, handleUnflat } from "../../helpers";

import styles from "./CallNoteHistoryTable.module.scss";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

import { TNote, ENoteKeys, EProjectNoteKeys, TProjectNote } from "../../types/TNote";
import SlideCheckbox from "../SlideCheckbox";
import PrimaryButton from "../buttons/PrimaryButton/PrimaryButton";
import { fetchAllProjectNotes, useLazyGetHistoryQuery, useLazyGetProjectNotesBySubmissionIdQuery, useUpdateProjectNotesMutation } from "../../store/services";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";

enum ETableHead {
  TYPE = "Note Type",
  SUBMITTED_BY = "Submitted By",
  DATE_CREATED = "Created At",
  NOTES_IN_SUBMISSION = "Notes",
}

interface ICallNoteHistoryTableProps {
  data: TNote[];
}

const cx = classNames.bind(styles);



const getRowClass = (params: RowClassParams) => {
  return cx("agg-row-cell");
};


// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const CallNoteHistoryTable = ({ data }: ICallNoteHistoryTableProps) => {
  const [mergeNotes, setMergeNotes] = useState<{ [id: string]: string }>({});

  const [updateProjectNotes] = useUpdateProjectNotesMutation();

  const [getHistory] = useLazyGetHistoryQuery();

  const dispatch = useDispatch<AppDispatch>();

  const handleMerge = useCallback(async () => {
    gridRef?.current?.api?.showLoadingOverlay();

    try {
      const submissionIds = Object.keys(mergeNotes)
        .map((id) => dispatch(fetchAllProjectNotes({ submissionId: id, page: 1 })));

      const projectNoteRes = await Promise.all(submissionIds);

      const projectNotes = projectNoteRes.map((res) => {
        if (!res.payload) throw new Error("Data for projects could not be fetched");
        return res.payload as TProjectNote[];
      });

      const newSubmissionId = projectNotes.find(item => item.length === Math.max(...projectNotes.map(item => item.length)))
      ?.[0]?.[EProjectNoteKeys.SUBMISSION_ID];

      if (!newSubmissionId) return;

      const updatePayload = projectNotes
        .filter((notes) => notes[0][EProjectNoteKeys.SUBMISSION_ID] !== newSubmissionId)
        .flat()
        .filter(note => note !== undefined)
        .map((note) => ({
          [EProjectNoteKeys.ID]: note[EProjectNoteKeys.ID],
          [EProjectNoteKeys.SUBMISSION_ID]: newSubmissionId
        }));

      const chunks = handleUnflat(updatePayload, 50);

      for (let chunk of chunks) {
        await updateProjectNotes(chunk);
      }

      setMergeNotes({});

      await getHistory('');

    } catch (e) {
      console.log(e);
    }

    gridRef?.current?.api?.hideOverlay();

  }, [mergeNotes, updateProjectNotes, getHistory, dispatch]);


  const rowData = useMemo(() => {
    const rows = data?.map((note) => {
      return {
        id: note[ENoteKeys.SUBMISSION_ID],
        type: note[ENoteKeys.TYPE],
        [ETableHead.TYPE]: note[ENoteKeys.TYPE],
        [ETableHead.SUBMITTED_BY]: handleCreator(
          note[ENoteKeys.SUBMITTED_BY] || ""
        ),
        [ETableHead.DATE_CREATED]: handleDate(
          new Date(note[ENoteKeys.DATE_CREATED])
        ),
        [ETableHead.NOTES_IN_SUBMISSION]: note[ENoteKeys.NOTES_IN_SUBMISSION],
        Download: (
          <a
            target="_blank"
            href={`${EEndpoints.API_ENDPOINT}/Project%20Note/Project%20Note%20Report/document?id=${note[ENoteKeys.ROW_ID]}`}
            // href={`${EEndpoints.API_SECURE}& id= ${ note[ENoteKeys.ROW_ID] } `}
            rel="noreferrer"
          >
            <IconDownload />
          </a>
        ),
      };
    });

    return rows;
  }, [data])

  const headers = useMemo(() => {
    const headers: ColDef[] = Object.values(ETableHead).map((header) => {
      return {
        headerName: header,
        field: header,
        sortable: true,
        resizable: true,
        unSortIcon: true,
        headerClass: cx("agg-header-cell"),
      };
    });
    headers.push({
      headerName: "ID",
      field: "id",
      hide: true,
    })
    headers.push({
      headerName: "ID",
      field: "type",
      hide: true,
    })
    headers.push({
      headerName: "",
      field: "Download",
      headerClass: cx("agg-header-cell"),
      cellRendererSelector: (params: ICellRendererParams) => {
        const component = {
          component: () => <div>{params.value}</div>,
        };
        return component;
      },
    });
    headers.unshift({
      headerName: "",
      field: "Merge",
      headerClass: cx("agg-header-cell"),
      headerComponent: () => (
        <PrimaryButton
          disabled={Object.keys(mergeNotes).length < 2}
          title="Merge"
          onClick={handleMerge}
        />),
      cellRendererSelector: (params: ICellRendererParams) => {
        const currentType = Object.values(mergeNotes)[0] || params.data.type;

        const handleChange = (checked: boolean, event: ChangeEvent<HTMLInputElement>) => {
          if (mergeNotes[params.data.id]) {
            setMergeNotes(prevState => {
              const newState = { ...prevState };
              delete newState[params.data.id];
              return newState;
            });
          } else {
            setMergeNotes(prevState => ({ ...prevState, [params.data.id]: params.data.type }));
          }
        }

        const component = {
          component: () => (
            <SlideCheckbox
              disabled={!!(currentType && currentType !== params.data.type)}
              checked={!!mergeNotes[params.data.id]}
              onChange={handleChange}
            />
          )
        }
        return component;
      },
    })

    return headers;
  }, [mergeNotes, handleMerge]);

  const defaultColGroupDef = useMemo<Partial<ColGroupDef>>(() => {
    return { headerClass: cx("agg-header-group") };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  }, []);

  const isFullWidthRow = useCallback((params: IsFullWidthRowParams) => {
    return params.rowNode.data.fullWidth;
  }, []);

  const getRowHeight = useCallback(
    (params: RowHeightParams): number | undefined | null => {
      return 58;
    },
    []
  );

  const getRowId = useCallback((params: any) => params.data.id, []);

  const gridRef = useRef<AgGridReact>(null);

  return (
    <div className={`${cx("box")} ag-theme-alpine`}>
      <MemoizedAgGrid
        ref={gridRef}
        getRowClass={getRowClass}
        rowData={rowData}
        rowDragManaged={true}
        animateRows
        defaultColGroupDef={defaultColGroupDef}
        columnDefs={headers}
        onGridReady={onGridReady}
        overlayLoadingTemplate="Loading..."
        suppressDragLeaveHidesColumns
        isFullWidthRow={isFullWidthRow}
        getRowHeight={getRowHeight}
        getRowId={getRowId}
      ></MemoizedAgGrid>
    </div>
  );
};


const MemoizedAgGrid = React.memo(AgGridReact<any>);

export default CallNoteHistoryTable;
