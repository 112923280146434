import LoginView from "./components/LoginView/LoginView";
import useApp from "./useApp";
import Main from "./components/Main/Main";

function App() {
  const { permissionAllowed } = useApp();

  if (permissionAllowed !== true) {
    return <LoginView />;
  }
  return (
    <Main />
  );
}

export default App;
