import classNames from "classnames/bind";

import { IconArrowDown, IconClose } from "../../assets/icons";

import styles from "./SidebarMenu.module.scss";
import CallTypes from "../CallTypes";
import IconForbidden from "../../assets/icons/IconsForbidden/IconForbidden";
import Tooltip from "../Tooltip/Tooltip";

interface ISidebarMenuProps {
  isOpen: boolean;
  isBlocked: boolean;
  onClose: () => void;
}

const cx = classNames.bind(styles);

const SidebarMenu = ({ onClose, isOpen, isBlocked }: ISidebarMenuProps) => {
  return (
    <div className={cx("sidebar-wrapper", { open: isOpen, blocked: isBlocked })}>
      <button disabled={isBlocked} className={cx("close", { open: isOpen })} onClick={onClose}>
        {!isBlocked && <IconArrowDown size="18px" />}
        {isBlocked && <Tooltip id="icon-forbidden" label="Save or discard call notes before changing call type">
          <span id="icon-forbidden">
            <IconForbidden size="18px" />
          </span>
        </Tooltip>}
      </button>
      <div className={cx("title")} />
      <div
        className={cx(
          "sidebar-inner",
          isOpen && "open",
          // newNotes.length > 0 && "disabled"
        )}
      >
        <CallTypes />
      </div>
    </div>
  );
};

export default SidebarMenu;
