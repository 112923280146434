import React, { useMemo, useState } from "react";
import { useGetProjectsQuery } from "../../store/services";
import { EKeyCallType, ICallType } from "../../constants";
import { createRows } from "../../helpers";
import { EDataKeys, TData } from "../../types/TData";
import { useSelector } from "react-redux";
import { IState } from "../../store";

function useProjects({ activeType }: { activeType: ICallType | null }) {
    const filters = useSelector((state: IState) => state.filtersReducer);
    const activeRowId = useSelector((state: IState) => state.sidebarReducer.activeId);

    const [numberOfRecords, setNumberOfRecords] = useState<number | null>(null);

    const newNotes = useSelector((state: IState) => state.newNotesReducer.newNotes
        .filter((el) => el.activeTypeName === activeType?.[EKeyCallType.NAME] || !el.activeTypeName));

    const isRefetch = useSelector((state: IState) => state.refetchReducer.value);

    const { data } = useGetProjectsQuery({
        activeType,
        filter: filters,
    }, { skip: !activeType });

    const filteredData = useMemo(() => {
        let filteredData = [...data || []];
        // if (filters.Search) {
        //     filteredData = filteredData?.filter((item) => {
        //         if (
        //             filters.Search &&
        //             !Object.keys(item).some((key: any) =>
        //                 String(item[key]).toLowerCase().includes(filters.Search.toLowerCase())
        //             )
        //         )
        //             return false;

        //         return true;
        //     });
        // }

        if (newNotes.length) {
            newNotes.forEach(note => {
                const foundRowIndex = filteredData.findIndex(item => item[EDataKeys.ROW_ID] === note[EDataKeys.ROW_ID]);

                if (foundRowIndex !== -1) {
                    filteredData[foundRowIndex] = { ...filteredData[foundRowIndex], [EDataKeys.CALL_NOTES]: note[EDataKeys.CALL_NOTES] }
                }
            })
        }


        return filteredData;
    }, [data, filters.Search, newNotes])

    const rowData = useMemo(() => {
        return createRows(filteredData || []);
    }, [filteredData])

    const activeRow = useMemo<TData | null>(
        () => filteredData?.find(item => item[EDataKeys.ROW_ID] === activeRowId) || null,
        [filteredData, activeRowId]
    );

    return {
        rowData,
        data: filteredData,
        activeRow,
        isRefetch,
        numberOfRecords,
        onNumberOfRecordsChange: (value: number | null) => setNumberOfRecords(value)
    }
}

export default useProjects;