import { IconProps } from "../types";

const IconArrowDown = ({ fillColor = "inherit", size = "24px" }: IconProps & { fillColor?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M12 3.75L12 20.25"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 13.5L12 20.25L18.75 13.5"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IconArrowDown;
