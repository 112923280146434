import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { projectApi } from "./project";

export interface ILoaderState {
  value: boolean;
}

const initialState: ILoaderState = {
  value: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(projectApi.endpoints.createProjectNote.matchPending, (state) => {
      state.value = true;
    })
    .addMatcher(projectApi.endpoints.createProjectNote.matchFulfilled, (state) => {
      state.value = false;
    })
  },
});

export const { setLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
