import { AnyAction, configureStore } from "@reduxjs/toolkit";

import { projectApi } from "./services";
import colsFilteredReducer, {
  IColsFilteredState, colsFilteredInitialState,
} from "./services/colsFiltered";
import newNotesReducer, { INewNotesState } from "./services/newNotes";
import popupReducer, { IPopupState } from "./services/popup";
import callTypeReducer, { ICallTypeState } from "./services/callType";
import sidebarReducer, { ISidebarState } from "./services/sidebar";
import refetchReducer, { IRefetchState } from "./services/refetch";
import newLinksReducer, { INewLinksState } from "./services/newLinks";
import activeTypeReducer, { IActiveTypeState } from "./services/activeType";
import loaderReducer, { ILoaderState } from "./services/loader";
import filtersReducer, { IFiltersState } from "./services/filters";
import userReducer, { IUserState } from "./services/user";
import { persistStore, persistReducer, PersistedState } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from 'redux-persist/lib/storage/session'
import { Reducer } from "react";
import createMigrate from "redux-persist/es/createMigrate";
export interface IState {
  projectApi: any;
  managerApi: any;
  historyApi: any;
  colsFilteredReducer: IColsFilteredState;
  newNotesReducer: INewNotesState;
  popupReducer: IPopupState;
  callTypeReducer: ICallTypeState;
  sidebarReducer: ISidebarState;
  refetchReducer: IRefetchState;
  newLinksReducer: INewLinksState;
  activeTypeReducer: IActiveTypeState;
  loaderReducer: ILoaderState;
  filtersReducer: IFiltersState;
  userReducer: IUserState;
}

const migrations = {
  0: (state: PersistedState) => ({...state, ...colsFilteredInitialState} as PersistedState),
};


export const store = configureStore({
  reducer: {
    [projectApi.reducerPath]: projectApi.reducer,
    colsFilteredReducer: (persistReducer({key: "colsFiltered", storage, version: 0, migrate: createMigrate(migrations)}, colsFilteredReducer) as Reducer<IColsFilteredState | undefined, AnyAction>),
    newNotesReducer: (persistReducer({ key: "newNotes", storage }, newNotesReducer) as Reducer<INewNotesState | undefined, AnyAction>),
    popupReducer,
    callTypeReducer,
    sidebarReducer,
    refetchReducer,
    newLinksReducer,
    activeTypeReducer: (persistReducer({ key: "type", storage: storageSession}, activeTypeReducer) as Reducer<IActiveTypeState | undefined, AnyAction>),
    loaderReducer,
    filtersReducer: (persistReducer({ key: "filters", storage }, filtersReducer) as Reducer<IFiltersState | undefined, AnyAction>),
    userReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(projectApi.middleware),
});

export const persistor = persistStore(store);


export type AppDispatch = typeof store.dispatch

