import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styles from "./Tooltip.module.scss";
import { ReactNode } from "react";

export default function Tooltip({ children, id, label }: Props) {
    return (
        <>
            {children}
            <ReactTooltip
                className={styles.tooltip}
                anchorId={id}
                place="bottom"
                content={label}
            />
        </>
    );
}

type Props = {
    children: ReactNode,
    id: string,
    label: string
}
