import React from "react";

import classNames from "classnames/bind";

import styles from "./ConfirmDeleteCustomCallTypeButton.module.scss";

const cx = classNames.bind(styles);

interface Props {
  onClick: () => void;
}

const ConfirmDeleteCustomCallTypeButton = ({ onClick }: Props) => {
  return (
    <button
      className={cx("confirm-delete-custom-call-type-button")}
      onClick={onClick}
    >
      <span>Delete</span>
    </button>
  );
};

export default ConfirmDeleteCustomCallTypeButton;
